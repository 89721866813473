<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected"/>
        <v-card>
            <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                v-if="Excel"
                color = "primary"
                dark
                small
                class="mb-2"
                @click="DescargarExcel()"
            >
            Descargar Tabla
            </v-btn>          
            <v-spacer></v-spacer>
            <v-btn
                v-if="Crear"
                color="primary"
                dark
                class="mb-2"
                @click="nuevoItem()"
                >
                Nuevo
            </v-btn>
            </v-card-title>
            <v-card-text>
            <v-data-table
            dense
            :headers="Cabecera"
            :items="Items"
            :items-per-page="10"
            class="elevation-1"
            loading-text="Cargando... Por favor, espere" 
            no-data-text="No hay datos disponibles"
            :loading="Loading"
            :footer-props="{
                'items-per-page-text': 'Resultados por página:',
                'page-text': '{0}-{1} de {2}' 
            }"
            >
                <template v-slot:[`item.visualizar`]="{ item }">                    
                    <v-simple-checkbox
                    v-if="item.visualizardisable"
                    v-model="item.visualizar"
                    color="error"
                    indeterminate
                    disabled
                    ></v-simple-checkbox>
                    <v-simple-checkbox
                    v-else
                    v-model="item.visualizar"
                    color="primary"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.agregar`]="{ item }">
                    <v-simple-checkbox
                    v-if="item.agregardisable"
                    v-model="item.agregar"
                    color="error"
                    indeterminate
                    disabled
                    ></v-simple-checkbox>
                    <v-simple-checkbox
                    v-else
                    v-model="item.agregar"
                    color="primary"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.editar`]="{ item }">
                    <v-simple-checkbox
                    v-if="item.editardisable"
                    v-model="item.editar"
                    color="error"
                    indeterminate
                    disabled
                    ></v-simple-checkbox>
                    <v-simple-checkbox
                    v-else
                    v-model="item.editar"
                    color="primary"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.eliminar`]="{ item }">
                    <v-simple-checkbox
                    v-if="item.eliminardisable"
                    v-model="item.eliminar"
                    color="error"
                    indeterminate
                    disabled
                    ></v-simple-checkbox>
                    <v-simple-checkbox
                    v-else
                    v-model="item.eliminar"
                    color="primary"
                    ></v-simple-checkbox>                 
                </template>
            </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading"],
    component: {
        Nuevo
    },
    data() {
        return {
            dialog: false,
            itemSelected: { }
        };
    },
    methods: {
        ...mapActions("roles", ["eliminarRoles"]),
        getColor(edad) {
            if (edad > 40)
                return "red";
            else if (edad > 25)
                return "info";
            else
                return "green";
        },
        eliminar(item) {
            this.$alertify.confirm(
                "Desea Eliminar este Item ID: " + item.id + "?", 
                () => {
                        this.eliminarRoles(item)
                        .then(r => {
                            this.$alertify.success("Item ID: " + item.id + "  Eliminado correctamente");
                        });
                    }, 
                () => this.$alertify.error("Se cancelo la acción")
            ).setHeader('<em> Aviso </em> ');
        },
        nuevoItem() {
            this.itemSelected = {
                id : 0,
                nombre : '',
                edad : 0,
                profesion : ''
            }
            this.dialog = true;
        },
        editarItem(item) {
            this.itemSelected = item
            this.dialog = true;
        },
        DescargarExcel(){
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({data, nombreArchivo, exportType})
        }
    },
    components: { Nuevo }
};
</script>
