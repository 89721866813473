<template>
<div>
    <v-dialog v-model="dialog" max-width="1000px">
        <v-card>
            <v-card-title>
                <span v-if="Item.id == ''" class="text-h6">Registro de Vehículo</span>
                <span v-else class="text-h6">Editar Vehículo</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != ''">ID: {{ Item.id }}</span>
            </v-card-title>
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Datos de Cliente</span>
            </v-toolbar>
            <v-card-text>
                <v-row class="pt-4">
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field label="Razon Social" hide-details="auto" v-model="RazonSocial" readonly required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field label="RUC" hide-details="auto" v-model="Ruc" readonly required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-autocomplete class="mt-5" :items="ItemsPlanta" v-model="Item.id_planta" label="Seleccione Planta " dense></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-toolbar height="30" color="#545554" dark>
                <span class="letra-11">Datos de Vehículo</span>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid" class="pt-4">
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete class="mt-5" @change="verConfiguracion" :items="ItemsTipo" v-model="Item.id_tipo" label="Seleccione Tipo Vehículo *" :rules="[(v) => !!v || 'Tipo de vehículo es requerido']" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field label="N° de Placa *" hide-details="auto" v-model="Item.placa" :rules="[(v) => !!v || 'Placa de vehículo es requerido']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field label="Codigo de Vehiculo " hide-details="auto" v-model="Item.codigo"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field label="Año de Fabricación " hide-details="auto" v-model="Item.fecha_fabricacion" hint="Ejemplo: 2016"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete :items="ItemsAplicacion" v-model="Item.ruta" label="Seleccione Aplicación *" :rules="[(v) => !!v || 'Aplicación es requerida']" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete :items="ItemsMarca" v-model="Item.id_marca" label="Seleccionar Marca *" :rules="[(v) => !!v || 'Marca es requerida']" dense>
                                <template v-slot:append-outer>
                                    <v-slide-x-reverse-transition mode="out-in">
                                        <v-icon color="success" @click="openModalMarca()" v-text="'mdi-plus-circle'"></v-icon>
                                    </v-slide-x-reverse-transition>
                                </template>
                            </v-autocomplete>
                            <!--
                                <v-btn-toggle mandatory>
                                    <v-btn color="success" height="24" width="15" class="text--color:#fff" x-small @click="openModalMarca()">
                                        <v-icon x-small>mdi-plus</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                                -->
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete :items="ItemsModelo" v-model="Item.id_modelo" label="Seleccionar Modelo *" :rules="[(v) => !!v || 'Modelo es requerido']" dense>
                                <template v-slot:append-outer>
                                    <v-slide-x-reverse-transition mode="out-in">
                                        <v-icon color="success" @click="openModalModelo()" v-text="'mdi-plus-circle'"></v-icon>
                                    </v-slide-x-reverse-transition>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete :items="arraycosto" v-model="Item.tipo_costo" label="Seleccione Tipo Costo *" :rules="[(v) => !!v || 'Tipo de costo es requerido']" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete :items="ItemsConfiguracion" v-model="Item.id_configuracion" label="Seleccione configuración *" @change="cargarneumaticos" :rules="[(v) => !!v || 'Configuración es requerida']" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-autocomplete v-if="Item.id != ''" :items="ItemsEstadoVehiculo" v-model="Item.estado" label="Seleccione Estado del Vehiculo *" dense></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="MostrarConfiguracion == true">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td class="pa-2"></td>
                                            <td class="pa-2" id="p6">
                                                <v-btn v-if="ItemLlantas[6]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[6] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p10">
                                                <v-btn v-if="ItemLlantas[10]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[10] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p14">
                                                <v-btn v-if="ItemLlantas[14]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[14] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p18">
                                                <v-btn v-if="ItemLlantas[18]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[18] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p22">
                                                <v-btn v-if="ItemLlantas[22]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[22] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="pa-2" id="p2">
                                                <v-btn v-if="ItemLlantas[2]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[2] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p4">
                                                <v-btn v-if="ItemLlantas[4]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[4] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p8">
                                                <v-btn v-if="ItemLlantas[8]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[8] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p12">
                                                <v-btn v-if="ItemLlantas[12]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[12] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p16">
                                                <v-btn v-if="ItemLlantas[16]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[16] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p20">
                                                <v-btn v-if="ItemLlantas[20]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[20] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="pa-2"></td>
                                        </tr>
                                        <tr>
                                            <td class="pa-2"></td>
                                        </tr>
                                        <tr>
                                            <td class="pa-2" id="p1">
                                                <v-btn v-if="ItemLlantas[1]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[1] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p3">
                                                <v-btn v-if="ItemLlantas[3]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[3] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p7">
                                                <v-btn v-if="ItemLlantas[7]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[7] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p11">
                                                <v-btn v-if="ItemLlantas[11]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[11] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p15">
                                                <v-btn v-if="ItemLlantas[15]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[15] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p19">
                                                <v-btn v-if="ItemLlantas[19]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[19] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="pa-2"></td>
                                            <td class="pa-2" id="p5">
                                                <v-btn v-if="ItemLlantas[5]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[5] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p9">
                                                <v-btn v-if="ItemLlantas[9]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[9] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p13">
                                                <v-btn v-if="ItemLlantas[13]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[13] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p17">
                                                <v-btn v-if="ItemLlantas[17]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[17] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                            <td class="pa-2" id="p21">
                                                <v-btn v-if="ItemLlantas[21]" small height="30" class="pa-0">
                                                    <v-img dark position="center" src="/images/llanta2.png" height="30" width="75">
                                                        <v-col class="text-center pt-1">
                                                            {{'P'+ ItemLlantas[21] }}
                                                        </v-col>
                                                    </v-img>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
                <MensajeTransicion :color="color" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo(false)"> Cerrar </v-btn>
                <v-btn color="info" :loading="loading" @click="agregar()">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!--MODAL ADD MARCA-->
    <v-row justify="center" v-if="modal_form_marca">
        <v-dialog v-model="dialogMarca" max-width="300px" @click:outside="cerrarDialogMarca">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form-marca" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Nombre de la marca *" required v-model="marca.descripcion" :rules="descripcionRules"></v-text-field>
                                </v-col>
                                <small v-if="mostrarLabelDuplicado">
                                    <font color="red">Marca duplicada</font>
                                </small>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addMarca(marca)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>

    <!--MODAL ADD MODELO-->
    <v-row justify="center" v-if="modal_form_modelo">
        <v-dialog v-model="dialogModelo" max-width="300px" @click:outside="cerrarDialogModelo">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form-modelo" v-model="valid">
                            <v-row>
                                <v-col cols="12" class="mt-5">
                                    <v-autocomplete :items="ItemsMarca" v-model="modelo.id_marca" label="Marca de neumático" :rules="[(v) => !!v || 'Marca es requerido']" dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Nombre del modelo *" placeholder="HN08" required v-model="modelo.descripcion" :rules="descripcionRules"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small>*Indicador de campo requerido</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" style="width: 100%" small color="#009688" @click="addModelo(modelo)">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions, mapState
} from "vuex";
import global from "../../global";
import Alerta from "../alertas.vue";
import MensajeTransicion from "../Alerta.vue"
import { notif } from "@/plugins/notyf"

export default {
    props: ["dialog", "Item"],
    components: {
        Alerta,
        MensajeTransicion
    },
    data() {
        return {
            valid: false,
            loading: false,
            marca: {},
            modelo: {},
            arraycosto: [{
                    value: 1,
                    text: "Por Kilometros",
                },
                {
                    value: 2,
                    text: "Por Horas",
                },
            ],
            ItemLlantas: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
                10: false,
                11: false,
                12: false,
                13: false,
                14: false,
                15: false,
                16: false,
                17: false,
                18: false,
                19: false,
                20: false,
                21: false,
                22: false,
            },
            ItemLlantasNombre: {
                nombre_p1: "p1",
                nombre_p2: "p2",
                nombre_p3: "p3",
                nombre_p4: "p4",
                nombre_p5: "p5",
                nombre_p6: "p6",
                nombre_p7: "p7",
                nombre_p8: "p8",
                nombre_p9: "p9",
                nombre_p10: "p10",
                nombre_p11: "p11",
                nombre_p12: "p12",
                nombre_p13: "p13",
                nombre_p14: "p14",
                nombre_p15: "p15",
                nombre_p16: "p16",
                nombre_p17: "p17",
                nombre_p18: "p18",
                nombre_p19: "p19",
                nombre_p20: "p20",
                nombre_p21: "p21",
                nombre_p22: "p22",
            },
            ItemsEstadoVehiculo: [{
                    value: 0,
                    text: "No Activo",
                },
                {
                    value: 1,
                    text: "Activo",
                },
            ],
            color: "success",
            verAlerta: false,
            mensaje: "",
            dialogMarca: false,
            dialogModelo: false,
            MostrarConfiguracion: true,
            mostrarLabelDuplicado: false,
            modal_form_modelo: false,
            modal_form_marca: false,
            modal_form_vehiculo: true,
            descripcionRules: [(v) => !!v || "Descripción es requerido"],
        };
    },
    computed: {
        ...mapGetters("vehiculo", [
            "RazonSocial",
            "Ruc",
            "ItemsPlanta",
            "ItemsTipo",
            "ItemsAplicacion",
            "ItemsMarca",
            "ItemsModelo",
            "ItemsConfiguracion",
            "Items",
            "ItemsNeumaticos",
        ]),
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("vehiculo", [
            "agregarVehiculo",
            "cargarConfiguracion",
            "cargarPosicionNeumatico",
            "agregarItemArray",
        ]),

        inicializarMarca: function () {
            this.marca = {
                descripcion: null,
            };
        },

        inicializarModelo: function () {
            this.modelo = {
                id_marca: null,
                descripcion: null,
            };
        },

        openModalMarca() {
            this.mostrarLabelDuplicado = false;
            this.inicializarMarca();
            this.$nextTick().then(() => {
                this.modal_form_marca = true;
            });
            this.dialogMarca = true;
        },

        openModalModelo() {
            this.mostrarLabelDuplicado = false;
            this.$nextTick().then(() => {
                this.modal_form_modelo = true;
            });
            this.inicializarModelo();
            if (this.Item.id_marca != null) {
                this.modelo.id_marca = this.Item.id_marca;
            }
            this.dialogModelo = true;
        },

        addMarca: async function (item) {
            if (
                !global.verificarDuplicados(
                    this.ItemsMarca,
                    item.descripcion.toUpperCase()
                )
            ) {
                //Guardar en la base de datos
                item.descripcion = item.descripcion.toUpperCase();

                let dato = await this.agregarItemArray({
                    url: "vehiculomarca/store",
                    item: item,
                });

                this.ItemsMarca.push({
                    value: dato.id,
                    text: dato.marca,
                });
                this.dialogMarca = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        addModelo: async function (item) {
            if (
                !global.verificarDuplicados(
                    this.ItemsModelo,
                    item.descripcion.toUpperCase()
                )
            ) {
                item.descripcion = item.descripcion.toUpperCase();
                let dato = await this.agregarItemArray({
                    url: "vehiculomodelo/store",
                    item: item,
                });
                this.ItemsModelo.push({
                    value: dato.id,
                    text: dato.modelo,
                    marca: dato.id_marca,
                });
                this.dialogModelo = false;
            } else {
                this.mostrarLabelDuplicado = true;
            }
        },

        mensajeUsuarioNoAutorizado: function () {
            this.color = "#FA694A";
            this.verAlerta = true;
            this.loading = false,
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        validar() {
            let existe = false;
            let dato = this.Items.find((el) => el.placa == this.Item.placa);
            if (dato) {
                existe = true;
            }
            return existe;
        },

        async agregar() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                if (this.Item.id == "") {
                    if (!this.validar()) {
                        await this.agregarVehiculo(this.Item).then((r)=>{
                            notif.success("Se registro el vehículo de placa: " + this.Item.placa);
                            this.loading = false;
                            this.MostrarConfiguracion = false;
                            this.cerrarDialogo(true);
                        });
                    } else {
                        this.loading = false;
                        this.color = "#FA694A";
                        this.verAlerta = true;
                        setTimeout(() => [(this.verAlerta = false)], 4000);
                        this.mensaje = "El número de placa ya se encuentra registrada";
                    }
                } else {
                    let autorizacion = global._autorizacion_usuario(this.Permisos, 80);
                    if (autorizacion) {
                        await this.agregarVehiculo(this.Item).then((r) => {
                            notif.success("Se actualizó el vehículo de placa: " + this.Item.placa);
                            this.loading = false;
                            this.MostrarConfiguracion = false;
                            this.cerrarDialogo(true);
                        });
                    }else{
                        this.mensajeUsuarioNoAutorizado();
                    }
                }
            }
        },

        cerrarDialogo(valor) {
            this.$emit("dialog", false);
            this.$emit("cerrarModal", valor);
            //this.cargarAplicacion;
        },

        cerrarDialogModelo() {
            this.dialogModelo = false;
            this.modal_form_modelo = false;
        },

        cerrarDialogMarca() {
            this.dialogMarca = false;
            this.modal_form_marca = false;
        },

        verConfiguracion() {
            this.Item.id_configuracion = null;
            this.$refs.form.resetValidation();
            this.cargarConfiguracion(this.Item.id_tipo);
        },
        cargarneumaticos(value = null, force = false) {
            this.MostrarConfiguracion = false;

            if (this.Item.id_configuracion != "" && !force) {
                this.cargarPosicionNeumatico(this.Item.id_configuracion).then((r) => {
                    this.ItemsNeumaticos.forEach((element, i) => { 
                        // if (element != null) {
                            this.ItemLlantas[i] = element;
                        //     this.$set(this.ItemLlantas, element, true);
                        // } else {
                        //     this.ItemLlantas[i + 1] = false;
                        //     this.$set(this.ItemLlantas, i + 1, false);
                        // }
                    });
                });
                this.$nextTick().then(() => {
                    this.MostrarConfiguracion = true;
                });
            } else {
                this.ItemLlantas = {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                    8: false,
                    9: false,
                    10: false,
                    11: false,
                    12: false,
                    13: false,
                    14: false,
                    15: false,
                    16: false,
                    17: false,
                    18: false,
                    19: false,
                    20: false,
                    21: false,
                    22: false,
                };
            }
        },
    },
    mounted() {
        //this.cargarneumaticos();
        if (this.Item.id != "") {
            this.cargarConfiguracion(this.Item.id_tipo);
            this.cargarPosicionNeumatico();
            this.cargarneumaticos();
        } else {
            this.cargarConfiguracion(null);
        }
    },
};
</script>

<style scoped>
.letra-11 {
    font-size: 11pt;
}
</style>
