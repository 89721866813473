<template>
<v-dialog v-model="dialog" persistent max-width="850px" @click:outside="cerrarDialogo(false)">
    <v-card>
        <v-card-title>
            <span v-if="Item.id == null" class="text-h6">Registrar criterio de aceptación</span>
            <span v-else class="text-h6">Editar criterios de aceptación</span>
            <v-spacer></v-spacer>
            <span v-if="Item.id != null">ID: {{ Item.id }}</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" style="margin-top: 30px">
                <v-row v-if="Item.id == null">
                    <v-col cols="12" sm="12" md="6">
                        <v-autocomplete :items="ArrayIndicador" label="Indicador Costo por kilómetro *" v-model="Item.costoindicador" readonly dense></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <v-autocomplete :items="ArrayAños" label="Año *" v-model="Item.anio" dense></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-if="
                    Item.costoindicador == 'Costo por kilómetro' ||
                    Item.costoindicador == 'Costo por hora'
                  ">
                        <v-autocomplete :items="ItemsModeloMedida" v-model="Item.modelomedida" label="Seleccione Modelo - Medida *" :rules="[(v) => !!v || 'Seleccione un Modelo - Medida']" dense></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" v-if="Item.costoindicador == 'Índice de reencauche'">
                        <span class="black--text">Criterio objetivo %</span>
                        <span class="red--text"> *</span><br />
                        <v-text-field hide-details="auto" v-model="Item.criterioobjetivo_calculo" type="number" :rules="criterioobjetivocalculo_rule" @input="calcularCriterios"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" v-if="Item.costoindicador == 'Índice de reencauche'">
                        <span class="black--text">Criterio deficiente %</span>
                        <span class="red--text"> *</span><br />
                        <v-text-field hide-details="auto" v-model="Item.criteriodeficiente_calculo" type="number" :rules="criteriodeficientecalculo_rule" @input="calcularCriterios"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <span class="black--text">Criterio objetivo</span>
                        <span class="red--text"> *</span> <br />
                        <span class="gris--text"> {{ infocriterio }} </span>
                        <v-text-field hide-details="auto" v-model="Item.criterioobjetivo" type="number" :rules="criterioobjetivo_rule" :readonly="habilitarcriterioobjetivo"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <span class="black--text">Criterio Tolerable máximo</span>
                        <span class="red--text"> *</span>
                        <v-text-field hide-details="auto" v-model="Item.tolerablemaximo" type="number" :rules="[
                      (v) => !!v || 'Criterio tolerable máximo es requerido',
                    ]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <span class="black--text">Criterio Tolerable mínimo</span>
                        <span class="red--text"> *</span> <br />
                        <v-text-field hide-details="auto" v-model="Item.tolerableminimo" type="number" :rules="[
                      (v) => !!v || 'Criterio tolerable mínimo es requerido',
                    ]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <span class="black--text">Criterio deficiente</span>
                        <span class="red--text"> *</span><br />
                        <span class="gris--text"> {{ infocriteriodeficiente }} </span>
                        <v-text-field hide-details="auto" v-model="Item.criteriodeficiente" type="number" :rules="criteriodeficiente_rule" :readonly="habilitarcriteriodeficiente"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="pa-2">
                        <font color="red" v-if="alerta">{{ mensajeAlerta }}</font>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo(false)"> Cerrar </v-btn>
            <v-btn color="info" @click="agregar()" :loading="loading" :disabled="!valid">
                Guardar
            </v-btn>
        </v-card-actions>
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
import alert from "@/components/alertas.vue";
import MensajeTransicion from "../Alerta.vue"
import { notif } from "@/plugins/notyf"
import axios from "axios";
import global from "@/global";

export default {
    props: ["dialog", "Item"],
    components: {
        alert,
        MensajeTransicion
    },
    created() {
        this.CargarAños();
    },
    data() {
        return {
            ArrayIndicador: [
                "Costo por kilómetro",
                "Costo por hora",
                "Índice de reencauche",
                "Índice de reencauchabilidad",
            ],
            ArrayAños: [],
            alerta: false,
            infocriterio: "",
            infocriteriodeficiente: "",
            habilitarcriterioobjetivo: false,
            habilitarcriteriodeficiente: false,
            mensajeAlerta: "",
            valid: false,
            modal_form_criterio: true,
            criterioobjetivo_rule: [],
            criteriodeficiente_rule: [],
            criterioobjetivocalculo_rule: [
                (v) => !!v || "Criterio objetivo es requerido",
                (v) =>
                v <= 100 ||
                "El Índice de reencauche (%): puede ser cero o mayor que uno y máximo 100.",
            ],
            criteriodeficientecalculo_rule: [
                (v) => !!v || "Criterio deficiente es requerido",
                (v) =>
                v <= 100 ||
                "El Índice de reencauche (%): puede ser cero o mayor que uno y máximo 100.",
            ],
            mensaje: "",
            verAlerta: false,
            loading: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapGetters("criterioaceptacion", ["ItemsModeloMedida", "Indicador"]),
    },
    methods: {
        ...mapActions("criterioaceptacion", [
            "agregarCriterioAceptacion",
            "cargarCriterioAceptacion",
        ]),

        mensajeUsuarioNoAutorizado: function () {
            this.verAlerta = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            this.loading = false;
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        async agregar() {
            const url = "criteriosaceptacion/consulta";
            let datos = {
                id_cliente: localStorage.getItem("idcliente"),
                indicador: this.Item.costoindicador,
                anio: this.Item.anio,
                modelomedida: this.Item.modelomedida,
            };
            this.loading = true;

            await axios.post(url, datos).then((response) => {
                if (response.data == 1) {
                    this.mensajeAlerta = "Este criterio ya fue registrado ";
                    this.alerta = true;
                    setTimeout(() => [(this.alerta = false)], 3000);
                    this.loading = false;
                } else {

                    //validación en vista
                    if (
                        this.Item.criteriodeficiente < this.Item.criterioobjetivo &&
                        this.Item.costoindicador != "Índice de reencauche" &&
                        this.Item.costoindicador != "Índice de reencauchabilidad"
                    ) {
                        this.mensajeAlerta =
                            "Criterio objetivo no debe ser mayor que el criterio deficiente";
                        this.alerta = true;
                        setTimeout(() => [(this.alerta = false)], 3000);
                        this.loading = false;
                    } else if (
                        parseFloat(this.Item.tolerablemaximo) >
                        parseFloat(this.Item.tolerableminimo) &&
                        parseFloat(this.Item.tolerablemaximo) >=
                        parseFloat(this.Item.criteriodeficiente) &&
                        parseFloat(this.Item.tolerableminimo) <=
                        parseFloat(this.Item.criteriodeficiente)
                    ) {
                        if (this.Item.id == null || this.Item.id == '') {
                            this.Registrar();
                        } else {
                            let autorizacion = global._autorizacion_usuario(this.Permisos, 25);
                            if (autorizacion) {
                                this.Registrar();
                            } else {
                                this.mensajeUsuarioNoAutorizado();
                            }
                        }
                    } else {
                        this.loading = false;
                        this.mensajeAlerta =
                            "Revisar los rangos de tolerable con los valores de criterio objetivo y deficiente ";
                        this.alerta = true;
                        setTimeout(() => [(this.alerta = false)], 4000);
                    }
                }
            });
        },

        async Registrar() {
            try {
                const url = "criterioaceptacion/store";
                let datos = {
                    id_cliente: localStorage.getItem("idcliente"),
                    criterio: this.Item,
                };
                await axios
                    .post(url, datos)
                    .then((response) => {
                        this.cerrarDialogo();
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        CargarAños() {
            const fecha = new Date();
            let anio_inicio = 2017;
            let anio_final = fecha.getFullYear() + 2;

            for (let i = anio_inicio; i <= anio_final; i++) {
                this.ArrayAños.push(i);
            }
        },
        calcularCriterios() {
            if (this.Item.criterioobjetivo_calculo) {
                this.Item.criterioobjetivo = this.Item.criterioobjetivo_calculo / 100;
            } else {
                if (this.Item.criterioobjetivo != "" && this.Item.criterioobjetivo) {
                    this.Item.criterioobjetivo = "";
                }
            }
            if (this.Item.criteriodeficiente_calculo) {
                this.Item.criteriodeficiente =
                    this.Item.criteriodeficiente_calculo / 100;
            } else {
                if (
                    this.Item.criteriodeficiente != "" &&
                    this.Item.criteriodeficiente
                ) {
                    this.Item.criteriodeficiente = "";
                }
            }
        },
        cerrarDialogo(valor = true) {
            if(valor){
                notif.success('Operación realizada con éxito');
                this.cargarCriterioAceptacion();
            }
            this.loading = false;
            this.$emit("closeDialog");
        },

        cargarFormulario(indicador) {
            if (indicador.costoindicador == "Índice de reencauche") {
                this.infocriterio = "(0 < c.o <= 100)";
                this.infocriteriodeficiente = "(0 <= c.d <= 100)";
                this.habilitarcriteriodeficiente = true;
                this.habilitarcriterioobjetivo = true;
            } else if (indicador.costoindicador == "Índice de reencauchabilidad") {
                this.infocriterio = "(0 < c.o <= 10)";
                this.infocriteriodeficiente = "(0 <= c.d <= 10)";
                this.criterioobjetivo_rule = [
                    (v) => !!v || "Criterio objetivo es requerido",
                    (v) => v < 11 || "Criterio objetivo debe ser menor o igual a 10",
                ];
                this.criteriodeficiente_rule = [
                    (v) => !!v || "Criterio deficiente es requerido",
                    (v) => v < 11 || "Criterio deficiente debe ser menor o igual a 10",
                ];
                this.habilitarcriteriodeficiente = false;
                this.habilitarcriterioobjetivo = false;
            } else {
                this.infocriterio = "(0 < c.o <= 5.0)";
                this.infocriteriodeficiente = "( c.o < c.d )";
                (this.criterioobjetivo_rule = [
                    (v) => !!v || "Criterio objetivo es requerido",
                    (v) => v < 6 || "Criterio objetivo debe ser menor o igual a 5",
                    (v) => v > 0 || "Criterio objetivo debe ser mayor a 0",
                ]),
                (this.criteriodeficiente_rule = [
                    (v) => !!v || "Criterio deficiente es requerido",
                ]),
                (this.habilitarcriteriodeficiente = false);
                this.habilitarcriterioobjetivo = false;
            }
        },
    },
};
</script>
