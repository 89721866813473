<template>
  <div class="pa-5">
    <v-col lg="12">
      <v-row align="center">
        <v-col lg="12">
          <v-card>
            <v-toolbar color="secondary" dark>
              <span class="text-h6">Filtrar neumáticos 12</span>
            </v-toolbar> 
            <v-container class="pa-4">
              <v-row class="pt-5">
                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete
                    :items="Marcas"
                    v-model="Item.marca"
                    label="Seleccione Marca"
                    dense
                    @change="onMarca"
                  ></v-autocomplete>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete
                    :items="modelosPorMarca"
                    v-model="Item.modelo"
                    label="Seleccione modelo"
                    dense
                  ></v-autocomplete>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete
                    :items="Medidas"
                    v-model="Item.medida"
                    label="Seleccione medida"
                    dense
                  ></v-autocomplete>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete
                    :items="tiposCosto"
                    v-model="Item.tipocosto"
                    label="Seleccione tipo de costo"
                    dense
                  ></v-autocomplete>
                </div>
              </v-row>

              <v-row>
                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete
                    :items="ejes"
                    v-model="Item.eje"
                    label="Seleccione eje"
                    dense
                  ></v-autocomplete>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                  <v-autocomplete
                    :items="reencauches"
                    v-model="Item.condicion"
                    label="Seleccione condición del neumático"
                    dense
                  ></v-autocomplete>
                </div>
              </v-row>

              <v-row>
                <v-col cols="1" sm="1" md="1" class="mt-2">
                  <v-btn color="success" @click="cargarDatos" small>
                    Filtrar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <vue-loaders
          v-if="loader == true"
          name="ball-beat"
          color="red"
          scale="1"
        ></vue-loaders>
      </v-row>

      <v-row justify="center">
        <v-col lg="12">
          <Neumaticos
            :Items="Items"
            :VerTooltip="VerTooltip"
            @seleccionarNeumatico="seleccionarNeumatico"
            v-if="Items.length > 0 && mostrarComponente == true"
          ></Neumaticos>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col lg="12">
          <v-card>
            <v-toolbar color="secondary" dark>
              <span class="text-h6">Tabla de comparación de neumáticos</span>
            </v-toolbar> 
            
            <v-container class="pt-2 pb-2">
              <v-row class="container">
                <div
                  v-for="(item, index) in filtros"
                  :key="index"
                  class="col-lg-3 col-sm-6 col-md-6 col-xs-12 ma-0 pa-0"
                >
                  <v-checkbox
                    class="ma-0 pa-0"
                    @click="cargarEncabezados"
                    v-model="item.valor"
                    :label="item.descripcion"
                  ></v-checkbox>
                </div>
              </v-row>

              <div class="table-responsive">
                <table class="letra-12" style="width: 100%" id="table">
                  <tr v-for="(item, index) in encabezados" :key="index">
                    <td
                      class="border texto-cen"
                      style="width: 15%; background-color: #6d6b6b"
                      v-if="item.visible == true"
                    >
                      <strong>
                        <font color="white">{{ item.descripcion }}</font>
                      </strong>
                    </td>
                    <td
                      :class="item.clase"
                      v-for="(item2, index2) in NeumaticosAComparar"
                      :key="index2"
                    >
                      <button
                        type="button"
                        style="
                          border-radius: 15px;
                          width: 145px;
                          background: none;
                          box-shadow: none;
                        "
                        @click="validacionNeumaticos(item2, index2)"
                        v-if="item.name == 'num_serie'"
                      >
                        <img
                          :src="imagen"
                          alt="neumatico"
                          :class="item2.clase"
                        />
                        <p style="color: grey; text-shadow: none">
                          {{ item2[item.name] }}
                        </p>
                      </button>
                      <font v-else>{{ item2[item.name] }}</font>
                    </td>
                  </tr>
                </table>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1" sm="1" md="1" class="mt-2">
          <v-btn color="success" @click="exportarAExcel" small>
            Exportar Excel
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import description from "../../components/instalacionneumaticos/description.vue";
import Neumaticos from "../../components/NeumaticoHorizontalTable.vue";
import axios from "axios";

export default {
  components: {
    description,
    Neumaticos,
  },
  data() {
    return {
      Item: {},
      Neumaticos: [],
      Cabecera: [
        {
          text: "",
          value: "num_serie",
          align: "center",
          width: "80",
        },
      ],
      tiposCosto: [
        {
          value: "K",
          text: "Kilómetros",
        },
        {
          value: "H",
          text: "Horas",
        },
      ],
      ejes: [
        {
          value: 1,
          text: "Direccional",
        },
        {
          value: 2,
          text: "Tracción",
        },
        {
          value: 3,
          text: "Eje libre",
        },
      ],
      modelosPorMarca: [],
      filtros: [
        {
          descripcion: "Nuevo",
          valor: false,
        },
        {
          descripcion: "1 Reencauche",
          valor: false,
        },
        {
          descripcion: "2 Reencauches",
          valor: false,
        },
        {
          descripcion: "3 Reencauches",
          valor: false,
        },
        {
          descripcion: "4 Reencauches",
          valor: false,
        },
        {
          descripcion: "5 Reencauches",
          valor: false,
        },
        {
          descripcion: "6 Reencauches",
          valor: false,
        },
        {
          descripcion: "Más de 6 Reencauches",
          valor: false,
        },
        {
          descripcion: "Ver costos reparación",
          valor: false,
        },
        {
          descripcion: "Ver costos",
          valor: false,
        },
        {
          descripcion: "Ver recorrido",
          valor: false,
        },
      ],
      reencauches: [
        {
          value: "N",
          text: "Nuevo",
        },
        {
          value: 1,
          text: "1 reencauche",
        },
        {
          value: 2,
          text: "2 reencauche",
        },
        {
          value: 3,
          text: "3 reencauche",
        },
        {
          value: 4,
          text: "4 reencauche",
        },
        {
          value: 5,
          text: "5 reencauche",
        },
        {
          value: 6,
          text: "6 reencauche",
        },
        {
          value: "R",
          text: "Más de 6 reencauche",
        },
      ],
      encabezados: [],
      arrayitems: [
        {
          descripcion: "serie",
          name: "num_serie",
          visible: true,
          clase: "border texto-cen pa-2",
          grupo: "",
        },
        {
          descripcion: "tipo costo",
          name: "descripcion_tipocosto",
          visible: true,
          clase: "border texto-cen pa-2",
          grupo: "",
        },
        {
          descripcion: "Marca",
          name: "marca",
          visible: true,
          clase: "border texto-cen pa-2",
          grupo: "",
        },
        {
          descripcion: "Modelo",
          name: "modelo",
          visible: true,
          clase: "border texto-cen pa-2",
          grupo: "",
        },
        {
          descripcion: "Medida",
          name: "medida",
          visible: true,
          clase: "border texto-cen pa-2",
          grupo: "",
        },
        {
          descripcion: "Costo reparación Nuevo",
          name: "costo_neumatico_nuevo_reparacion",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo_reparacion",
          check: "nuevo",
        },
        {
          descripcion: "Costo Nuevo",
          name: "costo_neumatico_nuevo",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo",
          check: "nuevo",
        },
        {
          descripcion: "Km|Hr Nuevo",
          name: "recorrido_neumatico_nuevo",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "kilometros",
          check: "nuevo",
        },
        {
          descripcion: "Costo x Km|Hr Nuevo",
          name: "costo_kmhr_nuevo",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "nuevo",
          check: "",
        },
        {
          descripcion: "Costo reparación R1",
          name: "costo_neumatico_reparacion_r1",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo_reparacion",
          check: "r1",
        },
        {
          descripcion: "Costo R1",
          name: "costo_neumatico_r1",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo",
          check: "r1",
        },
        {
          descripcion: "Km|Hr R1",
          name: "recorrido_neumatico_r1",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "kilometros",
          check: "r1",
        },
        {
          descripcion: "Costo x Km|Hr R1",
          name: "costo_kmhr_r1",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "r1",
          check: "",
        },
        {
          descripcion: "Costo reparación R2",
          name: "costo_neumatico_reparacion_r2",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo_reparacion",
          check: "r2",
        },
        {
          descripcion: "Costo R2",
          name: "costo_neumatico_r2",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo",
          check: "r2",
        },
        {
          descripcion: "Km|Hr R2",
          name: "recorrido_neumatico_r2",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "kilometros",
          check: "r2",
        },
        {
          descripcion: "Costo x Km|Hr R2",
          name: "costo_kmhr_r2",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "r2",
          check: "",
        },
        {
          descripcion: "Costo reparación R3",
          name: "costo_neumatico_reparacion_r3",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo_reparacion",
          check: "r3",
        },
        {
          descripcion: "Costo R3",
          name: "costo_neumatico_r3",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo",
          check: "r3",
        },
        {
          descripcion: "Km|Hr R3",
          name: "recorrido_neumatico_r3",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "kilometros",
          check: "r3",
        },
        {
          descripcion: "Costo x Km|Hr R3",
          name: "costo_kmhr_r3",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "r3",
          check: "",
        },
        {
          descripcion: "Costo reparación R4",
          name: "costo_neumatico_reparacion_r4",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo_reparacion",
          check: "r4",
        },
        {
          descripcion: "Costo R4",
          name: "costo_neumatico_r4",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo",
          check: "r4",
        },
        {
          descripcion: "Km|Hr R4",
          name: "recorrido_neumatico_r4",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "kilometros",
          check: "r4",
        },
        {
          descripcion: "Costo x Km|Hr R4",
          name: "costo_kmhr_r4",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "r4",
          check: "",
        },
        {
          descripcion: "Costo reparación R5",
          name: "costo_neumatico_reparacion_r5",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo_reparacion",
          check: "r5",
        },
        {
          descripcion: "Costo R5",
          name: "costo_neumatico_r5",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo",
          check: "r5",
        },
        {
          descripcion: "Km|Hr R5",
          name: "recorrido_neumatico_r5",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "kilometros",
          check: "r5",
        },
        {
          descripcion: "Costo x Km|Hr R5",
          name: "costo_kmhr_r5",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "r5",
          check: "",
        },
        {
          descripcion: "Costo reparación R6",
          name: "costo_neumatico_reparacion_r6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo_reparacion",
          check: "r6",
        },
        {
          descripcion: "Costo R6",
          name: "costo_neumatico_r6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "costo",
          check: "r6",
        },
        {
          descripcion: "Km|Hr R6",
          name: "recorrido_neumatico_r6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "kilometros",
          check: "r6",
        },
        {
          descripcion: "Costo x Km|Hr R6",
          name: "costo_kmhr_r6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-1",
          grupo: "r6",
          check: "",
        },
        {
          descripcion: "Costo reparación de más de 6 R",
          name: "costo_reenc_reparacion_mayores_6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo_reparacion",
          check: "m6",
        },
        {
          descripcion: "Costo de más de 6 R",
          name: "costo_reenc_mayores_6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "costo",
          check: "m6",
        },
        {
          descripcion: "Km|Hr de más de 6 R",
          name: "recorrido_reenc_mayores_6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "kilometros",
          check: "m6",
        },
        {
          descripcion: "Costo x Km|Hr de más de 6 R",
          name: "costo_kmhr_reenc_mayores_6",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-2",
          grupo: "m6",
          check: "",
        },
        {
          descripcion: "Costo acumulado de reparación",
          name: "costo_acumulado_reparacion",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-3",
          grupo: "costo_reparacion",
          check: "costo_reparacion",
        },
        {
          descripcion: "Costo acumulado",
          name: "costo_acumulado",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-3",
          grupo: "costo",
          check: "costo",
        },
        {
          descripcion: "Km|Hr Acumulado",
          name: "recorrido_kmhr_acumulado",
          visible: false,
          clase: "border texto-cen pa-2 bg-celda-3",
          grupo: "kilometros",
          check: "kilometros",
        },
        {
          descripcion: "Costo x Km|Hr Acumulado",
          name: "costo_kmhr_acumulado",
          visible: true,
          clase: "border texto-cen pa-2 bg-celda-3",
          grupo: "",
          check: "",
        },
        {
          descripcion: "Km|Hr Proyectados (Km|Hr x mm)",
          name: "kilometraje_proyectado",
          visible: true,
          clase: "border texto-cen pa-2 bg-celda-4",
          grupo: "",
          check: "",
        },
      ],
      NeumaticosAComparar: [
        {
          id: "",
          pos: 0,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          id: "",
          pos: 1,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          id: "",
          pos: 2,
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 3,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
        {
          pos: 4,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        },
      ],
      opcionesPagina: [
        { value: 6, text: 6 },
        { value: 12, text: 12 },
        { value: 18, text: 18 },
        { value: "", text: "All" },
      ],
      mostrarComponente: false,
      neumaticoSeleccionado: null,
      imagen: axios.defaults.imageURL + "images/llanta.png",
      loader: false,
      VerTooltip: true,
      Neumaticos: [],
    };
  },
  computed: {
    ...mapState("reportes", ["Marcas", "Modelos", "Medidas"]),
    ...mapState("neumatico", ["Items"]),
  },
  methods: {
    ...mapActions("reportes", [
      "DatosMarcasModelosMedidas",
      "DatosReporteComparativoConfiguracion",
    ]),
    ...mapActions("neumatico", ["cargarNeumaticos", "filtrarNeumaticos"]),

    onMarca: function () {
      this.modelosPorMarca = [];
      this.Modelos.forEach((element) => {
        if (element.id_marca == this.Item.marca) {
          this.modelosPorMarca.push(element);
        }
      });
      this.DatosMarcasModelosMedidas(this.Item);
    },
    /*
    onBusqueda: function () {
      if (this.search == "") {
        this.cargarComponenteNeumaticos();
      } else {
        let aux = [];
        this.Neumaticos.forEach((element) => {
          element.forEach((element1) => {
            let serie = element1.num_serie;
            if (serie.includes(this.search) == true) {
              aux.push(element1);
            }
          });
        });
        this.paginacion(aux.length, aux);
      }
    },
    */

    seleccionarNeumatico: function (item) {
      this.neumaticoSeleccionado = item;
    },

    cargarDatos: async function () {
      this.loader = true;
      this.mostrarComponente = false;
      await this.filtrarNeumaticos(this.Item);
      this.Items.forEach((element) => {
        element.descripcion = element.num_serie;
      });
      //this.paginacion(this.Items.length, this.Items);
      this.loader = false;
      this.mostrarComponente = true;
      this.$forceUpdate();
    },

    validacionNeumaticos: async function (item, index) {
      let neumaticorepetido = false;
      if (item.asignado == false) {
        if (this.neumaticoSeleccionado != null) {
          this.NeumaticosAComparar.forEach((element) => {
            if (element.num_serie == this.neumaticoSeleccionado.num_serie) {
              neumaticorepetido = true;
            }
          });
          if (neumaticorepetido == false) {
            //consultar a neumatico comparación
            let neumatico = await this.DatosReporteComparativoConfiguracion({
              id: this.neumaticoSeleccionado.id,
            });
            neumatico.pos = index;
            neumatico.asignado = true;
            neumatico.clase = "imagen-neumatico2";
            this.NeumaticosAComparar[index] = neumatico;
            this.neumaticoSeleccionado = null;
          
          }
        } else {
          console.log("neumático no seleccionado");
        }
      } else {
        this.NeumaticosAComparar[index] = {
          pos: index,
          id: "",
          asignado: false,
          clase: "imagen-neumatico1",
        };
        this.neumaticoSeleccionado = null;
      }
      this.$forceUpdate();
    },

    mostrarCeldas: function (element) {
      if (this.filtros[8].valor == true) {
        if (element.grupo == "costo_reparacion") {
          element.visible = true;
        }
      }
      if (this.filtros[9].valor == true) {
        if (element.grupo == "costo") {
          element.visible = true;
        }
      }
      if (this.filtros[10].valor == true) {
        if (element.grupo == "kilometros") {
          element.visible = true;
        }
      }
    },

    cargarEncabezados: function () {
      this.encabezados = [];
      this.arrayitems.forEach((element) => {
        if (this.filtros[0].valor == true) {
          if (element.grupo == "nuevo") {
            element.visible = true;
          } else if (element.check == "nuevo") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "nuevo") {
            element.visible = false;
          }
        }

        if (this.filtros[1].valor == true) {
          if (element.grupo == "r1") {
            element.visible = true;
          } else if (element.check == "r1") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "r1") {
            element.visible = false;
          }
        }

        if (this.filtros[2].valor == true) {
          if (element.grupo == "r2") {
            element.visible = true;
          } else if (element.check == "r2") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "r2") {
            element.visible = false;
          }
        }

        if (this.filtros[3].valor == true) {
          if (element.grupo == "r3") {
            element.visible = true;
          } else if (element.check == "r3") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "r3") {
            element.visible = false;
          }
        }

        if (this.filtros[4].valor == true) {
          if (element.grupo == "r4") {
            element.visible = true;
          } else if (element.check == "r4") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "r4") {
            element.visible = false;
          }
        }

        if (this.filtros[5].valor == true) {
          if (element.grupo == "r5") {
            element.visible = true;
          } else if (element.check == "r5") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "r5") {
            element.visible = false;
          }
        }

        if (this.filtros[6].valor == true) {
          if (element.grupo == "r6") {
            element.visible = true;
          } else if (element.check == "r6") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "r6") {
            element.visible = false;
          }
        }

        if (this.filtros[7].valor == true) {
          if (element.grupo == "m6") {
            element.visible = true;
          } else if (element.check == "m6") {
            this.mostrarCeldas(element);
          }
        } else {
          if (element.grupo == "m6") {
            element.visible = false;
          }
        }

        if (this.filtros[8].valor == true) {
          if (
            element.grupo == "costo_reparacion" &&
            element.check == "costo_reparacion"
          ) {
            element.visible = true;
          }
        } else {
          if (element.grupo == "costo_reparacion") {
            element.visible = false;
          }
        }

        if (this.filtros[9].valor == true) {
          if (element.grupo == "costo" && element.check == "costo") {
            element.visible = true;
          }
        } else {
          if (element.grupo == "costo") {
            element.visible = false;
          }
        }

        if (this.filtros[10].valor == true) {
          if (element.grupo == "kilometros" && element.check == "kilometros") {
            element.visible = true;
          }
        } else {
          if (element.grupo == "kilometros") {
            element.visible = false;
          }
        }

        if (element.visible == true) {
          this.encabezados.push(element);
        }
      });
    },
    /*
    mostrarPagina: async function () {
      this.datosPaginados = [];
      this.datosPaginados.push(this.Neumaticos[this.pagina_actual - 1]);
      this.$forceUpdate();
    },

    paginacion: function (total, array) {
      this.Neumaticos = [];
      this.datosPaginados = [];
      let items_por_td = this.porPagina != "" ? this.porPagina : total;
      this.pagina_actual = 1;
      this.paginas = Math.ceil(total / items_por_td);
      var index = 0;
      for (let i = 0; i < this.paginas; i++) {
        var temporal = [];
        for (let j = 0; j < items_por_td && index < total; j++, index++) {
          temporal.push(array[index]);
        }
        this.Neumaticos.push(temporal);
      }
      this.datosPaginados.push(this.Neumaticos[this.pagina_actual - 1]);
    },
    */
    _json_to_query_string: function (json) {
      json = Object.keys(json).map(item => {
        return item + '=' + json[item] || '';
      });
      return json.join("&");
    },

    exportarAExcel: function(){
      let array_id_neumaticos = [];
      this.NeumaticosAComparar.forEach(element => {
        if(element.id != ""){
          array_id_neumaticos.push(element.id);
        }
      });

      location.href =
        axios.defaults.baseURL + "excel/reportecomparativoneumaticos?" +
        this._json_to_query_string({
          id_cliente: localStorage.getItem('idcliente'),
          array_id_neumaticos: array_id_neumaticos
        });
    }
    /*
    titulo: function (estado) {
      if (estado == 1) {
        return "Listo para instalar";
      } else if (estado == 2) {
        return "En reparación";
      } else if (estado == 3) {
        return "Instalado";
      } else if (estado == 4) {
        return "En reencauche";
      } else if (estado == 5) {
        return "Listo para instalar";
      } else {
        return "";
      }
    },

    subtitulo: function (estado) {
      if (estado == 1) {
        return "";
      } else if (estado == 2) {
        return "Registre el costo de reparación";
      } else if (estado == 3) {
        return "";
      } else if (estado == 4) {
        return "Registre el costo de reencauche";
      } else {
        return "";
      }
    },
    */
  },
  mounted: async function () {
    this.loader = true;
    this.DatosMarcasModelosMedidas();
    await this.cargarNeumaticos();
    this.Items.forEach((element) => {
      element.descripcion = element.num_serie;
    });
    //let total = this.Items.length;
    //this.paginacion(total, this.Items);
    this.mostrarComponente = true;
    this.loader = false;
    this.cargarEncabezados();
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

td {
  vertical-align: top;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-bottom {
  border-bottom: solid;
  border-width: 1px;
}

.border-top {
  border-top: solid;
  border-width: 1px;
}

.border-left {
  border-left: solid;
  border-width: 1px;
}

.border-right {
  border-right: solid;
  border-width: 1px;
}

.no-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-border-left {
  border-left: none;
}

.no-border-right {
  border-right: none;
}

.texto-cen {
  text-align: center;
}

.texto-izq {
  text-align: left;
}

.texto-just {
  text-align: justify;
}

.inline {
  display: inline-block;
}

.pa-4 {
  vertical-align: text-top;
  vertical-align: top;
}

.texto-cen {
  text-align: center;
}

.letra-10 {
  font-size: 11px;
}

.letra-12 {
  font-size: 12px;
}

.letra-14 {
  font-size: 14px;
}

.bg-celda-1 {
  background: #d2fcfe;
}

.bg-celda-2 {
  background: #ebf2f2;
}

.bg-celda-3 {
  background: #fbc4ee;
}

.bg-celda-4 {
  background: #9ec540;
}

.imagen-neumatico1 {
  width: 60px;
  opacity: 0.65;
}

.imagen-neumatico2 {
  width: 60px;
  opacity: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: -85px;
  left: 15%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#table {
  display: block;
  /* important */
  width: 100%;
  overflow-x: scroll;
}
</style>
