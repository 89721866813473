<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />

        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                class="elevation-1 mt-2" loading-text="Cargando... Por favor, espere"
                :loading="Loading"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                'items-per-page-text': 'Resultados por página:',
                'page-text': '{0}-{1} de {2}' 
                  }">
                <template v-slot:[`item.img_firma`]="{ item }">
                    <v-chip v-if="item.img_firma" color="success" outlined small>
                        SI
                    </v-chip>
                    <v-chip v-else color="error" outlined small>
                        NO
                    </v-chip>
                </template>
                <template v-slot:[`item.rol`]="{ item }">
                    <v-chip color="success" outlined small>
                        {{ item.rol }}
                    </v-chip>
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="editarItem(item)" v-bind="attrs" v-on="on">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small @click="eliminar(item)" v-bind="attrs" v-on="on">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: {},
            mensaje: "",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("usuario", ["eliminarUser"]),

        mensajeUsuarioNoAutorizado: function () {
            this.verAlerta = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        nuevoItem() {
            console.log("clcik");
            let autorizacion = global._autorizacion_usuario(this.Permisos, 16);
            if (autorizacion) {
                this.itemSelected = {
                    id: 0,
                    nombre: '',
                    apellidos: '',
                    telefono: '',
                    selectedRoles: '',
                    selectedEmpresa: [],
                    imagen_firma: '',
                    imagen: '',
                    email: '',
                    pasword: ''
                }
                this.dialog = true;
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },

        eliminar(item) {
            let autorizacion = global._autorizacion_usuario(this.Permisos, 18);
            if (autorizacion) {
                this.$alertify.confirm(
                    "Desea Eliminar este Item ID: " + item.id + "?",
                    () => {
                        this.eliminarUser(item)
                            .then(r => {
                                this.$alertify.success("Item ID: " + item.id + "  Eliminado correctamente");
                            })
                            .catch(error => {
                                console.log(error)
                            });
                    },
                    () => this.$alertify.error("Se cancelo la acción")
                ).setHeader('<em> Aviso </em> ');
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },

        editarItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },

        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            })
        }
    }
};
</script>
