<template>
  <div>
    <v-card>

      <v-toolbar color="secondary" dark>
        <span class="text-h6"> {{ Titulo }} : {{ ItemTotal }}</span>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
              <a href="" style="text-decoration:none !important; color: white;">
                <v-icon dark>mdi-help-circle</v-icon>
              </a>
            </v-btn>
          </template>
          <span>Panel de ayuda en desarrollo</span>
        </v-tooltip>
      </v-toolbar>


      <v-card-title>
        <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
          Descargar Tabla
        </v-btn>

        <v-spacer></v-spacer>

        <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
          hide-details></v-text-field>

        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-3" dark v-if="Crear" @click="nuevoItem()">
          Nuevo
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Exportar
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in opcionesAexportar" :key="index" @click="exportarAExcel(item.text)">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search" :loading="loading"
        loading-text="Cargando... Por favor, espere" class="elevation-1" no-data-text="No hay datos disponibles"
        :footer-props="{
          'items-per-page-text': 'Resultados por página:',
          'page-text': '{0}-{1} de {2}'
        }">
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark @click="editarItem(item)"
                v-bind="attrs" v-on="on">
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn height="22" width="22" color="green" fab x-small dark @click="modalRepararNeumatico(item)"
                v-bind="attrs" v-on="on">
                <v-icon x-small>mdi-brightness-5</v-icon>
              </v-btn>
            </template>
            <span>Reparar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn height="22" width="22" color="#8E938E" fab x-small dark @click="modalReencaucharNeumatico(item)"
                v-bind="attrs" v-on="on">
                <v-icon x-small>mdi-brightness-5</v-icon>
              </v-btn>
            </template>
            <span>Reencauchar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn height="22" width="22" color="red" fab x-small dark @click="modalScrapNeumatico(item)"
                v-bind="attrs" v-on="on">
                <v-icon x-small>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Scrap</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue lighten-2" x-small dark @click="verImagen(item)" v-bind="attrs" v-on="on">
                Ver imagen
              </v-btn>
            </template>
            <span>Imagen</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <Imagen ref="Imagen" :dialog="dialog_image" @dialog="dialog_image = $event" :Item="itemSelected" />

    <Editar ref="scrap" Titulo="Editar Neumático en mal estado" :dialog="dialog_edit" @dialog="dialog_edit = $event"
      :Item="itemSelected" :MotivosScrap="MotivosScrap" />

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="grey" dark height="40">
          {{ title }}
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="12">
                <h3 class="text-center">{{ mensaje }}</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="info" @click="guardar()"> {{ nameButton }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_warning" persistent max-width="500px">
      <v-card>
        <v-toolbar color="grey" dark height="40">
          AVISO
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="12" md="12">
                <h3 class="text-center">¡Por favor, ingrese su remanente final para continuar! </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog_warning = false"> Cancelar </v-btn>
          <v-btn color="info" @click="enviarDialog()"> Llenar remanente </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import Editar from "../scrap/editar.vue";
import Imagen from "../scrap/imagen.vue";
import global from "../../global";
import { mapState } from "vuex";

export default {
  props: [
    "Titulo",
    "Cabecera",
    "loading",
    "Items",
    "Item",
    "ItemTotal",
    "Excel",
    "Crear",
  ],
  components: {
    Imagen,
    Editar,
  },
  data() {
    return {
      dialog: false,
      title: "",
      mensaje: "",
      search: "",
      nameButton: "",
      dialog_edit: false,
      dialog_image: false,
      MotivosScrap: [],
      itemSelected: {},
      dialog_warning: false,
      opcionesAexportar: [
        {
          id: 1,
          text: "Exportar Excel",
        },
        {
          id: 2,
          text: "Exportar CSV",
        },
      ],
      /* extension: "", */
      ruta: "",
    };
  },
  methods: {
    ...mapState("enmalestado", ["cargarNeumaticos"]),

    verImagen(item) {
      this.itemSelected = item;
      this.dialog_image = true;
    },

    exportarAExcel(text) {
      location.href =
        global.ruta_api +
        "excel/reporteneumaticosenmalestado?" +
        global._json_to_query_string({
          id_cliente: localStorage.getItem("idcliente"),
          extension: text,
        });
      console.log("Location", location.href);
    },

    editarItem(item) {
      item.remanente_final = item.remanente_final == "-" ? null : item.remanente_final;
      item.fecha = item.fecha_scrap;
      this.itemSelected = item;
      this.dialog_edit = true;
    },

    modalRepararNeumatico(item) {
      this.dialog = true;
      this.itemSelected = item;
      this.ruta = "neumaticos/enviarAreparar";
      this.title = "Reparar neumático en mal estado: " + item.num_serie;
      this.mensaje = "¿Esta seguro que desea reparar este neumatico?";
      this.nameButton = "Reparar";
    },

    modalReencaucharNeumatico(item) {
      this.dialog = true;
      this.itemSelected = item;
      this.ruta = "neumaticos/enviarAreencauchar";
      this.title = "Reencauchar neumático en mal estado: " + item.num_serie;
      this.mensaje = "¿Esta seguro que desea reencauchar este neumatico?";
      this.nameButton = "Reencauchar";
    },

    modalScrapNeumatico(item) {
      this.dialog = true;
      this.itemSelected = item;
      this.ruta = "neumaticos/enviarAscrap";
      this.title = "Scrap neumático en mal estado: " + item.num_serie;
      this.mensaje = "¿Esta seguro que desea enviar este neumatico a SCRAP?";
      this.nameButton = "Enviar a SCRAP";
    },

    async guardar() {
      if (this.itemSelected.remanente_final == "-" || this.itemSelected.remanente_final === null) {
        this.dialog_warning = true;
        this.dialog = false;
      } else {
        try {
          const url = this.ruta;
          const data = {
            id_cliente: localStorage.getItem("idcliente"),
            id_usuario: localStorage.getItem("id"),
            id_neumatico: this.itemSelected.id,
          };
          await axios
            .post(url, data)
            .then((response) => {
              this.dialog = false;
              this.$emit("actualizartabla");
              this.$alertify.success("Operación realizada satisfactoriamente");
            })
            .catch((error) => {
              this.errorMessage = error.response.data;
              console.error("error!", error);
            });
        } catch (error) {
          console.log(error);
        }
      }

    },

    enviarDialog() {
      this.dialog_warning = false;
      this.editarItem(this.itemSelected);
    }
    ,
  },
};
</script>
