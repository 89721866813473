import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Item: null,
    Items: [],
    Loader: true,
    ItemTotal: 0,
    TipoCambio: null,
    MotivosScrap: [],
    Marcas: [],
    Modelos: [],
    Medidas: [],
    Disenios: [],
    Empresas: [],
  },
  mutations: {
    LlenarItem(state, data) {
      state.Item = data;
    },
    LlenarTipoCambio(state, data) {
      state.TipoCambio = data;
    },
    LlenarItems(state, data) {
      state.Items = data;
    },
    LlenarNeumatico(state, data) {
      state.Item = data;
    },
    AsignarItemTotal(state, data) {
      state.ItemTotal = data;
    },
    LlenarMotivosScrap(state, data) {
      state.MotivosScrap = data;
    },
    LlenarMarcas(state, data) {
      state.Marcas = data;
    },
    LlenarModelos(state, data) {
      state.Modelos = data;
    },
    LlenarMedidas(state, data) {
      state.Medidas = data;
    },
    LlenarDisenios(state, data) {
      state.Disenios = data;
    },
    LlenarEmpresas(state, data) {
      state.Empresas = data;
    },
    StateLoader(state, data) {
      state.Loader = data;
    },
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    asignarObjetoNeumatico: function ({ commit }, data) {
      commit("LlenarNeumatico", data);
    },

    verNeumatico: async function ({ commit, dispatch }, id) {
      try {
        const url = "neumaticos/find";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          id_neumatico: id,
        };

        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarItem", response.data.success.datos);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarNeumaticosMinified: async function ({ commit, dispatch }) {
      try {
        const url = "neumaticos/showminified";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };

        await axios
          .post(url, data)
          .then((response) => {
            let neumaticos = [];
            response.data.data.forEach((element) => {
              neumaticos.push({ value: element.id, text: element.num_serie });
            });
            commit("LlenarItems", neumaticos);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarNeumaticos: async function ({ commit, dispatch }) {
      try {
        const url = "neumaticos/index";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };

        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarItems", response.data.success.resultado);
            commit("AsignarItemTotal", response.data.success.resultado.length);
            commit("StateLoader", false);
            //this.Items = response.data.success.resultado;
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarDatosAdicionales: async function ({ commit, dispatch }) {
      try {
        const url = "neumaticos/list";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };
        await axios
          .post(url, data)
          .then((response) => {
            let marcaNeumaticoList = [];
            response.data.success.marcas.forEach((element, i) => {
              marcaNeumaticoList[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            let modeloNeumaticoList = [];
            response.data.success.modelos.forEach((element, i) => {
              modeloNeumaticoList[i] = {
                value: element.id,
                text: element.descripcion,
                marca: element.marca,
              };
            });

            let medidaNeumaticoList = [];
            response.data.success.medidas.forEach((element, i) => {
              medidaNeumaticoList[i] = {
                value: element.id,
                text: element.descripcion,
              };
            });

            let disenioNeumaticoList = [];
            response.data.success.disenios.forEach((element, i) => {
              disenioNeumaticoList[i] = {
                value: element.id,
                text: element.nombre,
              };
            });

            let empresaList = [];
            response.data.success.empresas.forEach((element, i) => {
              empresaList[i] = {
                value: element.id,
                text: element.razon_social,
              };
            });

            let motivoScrapList = [];
            response.data.success.motivosscrap.forEach((element, i) => {
              motivoScrapList[i] = {
                value: element.id,
                text: element.motivo,
              };
            });

            //commit("LlenarMotivosScrap", motivoScrapList);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    filtrarNeumaticos: async function ({ commit, dispatch }, item) {
      try {
        const url = "neumaticos/filters";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          id_marca: item.marca,
          id_modelo: item.modelo,
          id_medida: item.medida,
          condicion: item.condicion,
          tipocosto: item.tipocosto,
          eje: item.eje,
        };

        await axios
          .post(url, data)
          .then((response) => {
            commit("LlenarItems", response.data.success.resultado);
            commit("AsignarItemTotal", response.data.success.resultado.length);
            commit("StateLoader", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    agregarNeumatico: async function ({ commit, dispatch }, datos) {
      var url =
        datos.neumatico.id == "" || datos.neumatico.id == null
          ? "neumaticos/store"
          : "neumaticos/update";
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        id_usuario: localStorage.getItem("id"),
        neumatico: datos.neumatico,
        grupo: datos.grupo,
      };
      await axios
        .post(url, data)
        .then((response) => {
          commit("StateLoader", true);
          dispatch("cargarNeumaticos");
        })
        .catch((error) => {
          dispatch("messageErrorAlert");
        });
    },

    validateSerialNumber: async function ({ commit, dispatch }, serie) {
      var url = "neumaticos/validateserialnumber";
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        num_serie: serie,
      };
      let rpta = "";
      await axios
        .post(url, data)
        .then((response) => {
          rpta = response.data.message;
        })
        .catch((error) => {
          rpta = error.response.data.message;
          console.error("error!", error);
          //dispatch("messageErrorAlert");
        });
      return rpta;
    },

    agregarItemArray: async function ({ commit, dispatch }, datos) {
      var url = datos.url;
      const data = {
        id_cliente: localStorage.getItem("idcliente"),
        item: datos.item,
      };
      let rpta = null;
      await axios
        .post(url, data)
        .then((response) => {
          rpta = response.data.success;
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
      return rpta;
    },
  },
};
