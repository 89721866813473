import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    Items: [],
    ItemsSelect: [],
    Loading:true
  },
  getters: {
    Items(state) {
      return state.Items;
    },
    ItemsSelect(state) {
      return state.ItemsSelect;
    },
  },
  mutations: {
    LlenarItems(state, data) {
      state.Items = data;
    },
    LlenarItemsSelect(state, data) {
      state.ItemsSelect = data;
    },
    LlenarLoading(state, data) {
      state.Loading = data; 
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarPermisos: async function ({ commit, dispatch }) {
      try {
        var dataaux = [];
        var select = [];
        const url = "permisos";
        await axios
          .get(url)
          .then((response) => {
            var con = 0;
            for (
              var i = 0;
              i < response.data.success.resultado.roles.length;
              i++
            ) {
              select[i] = response.data.success.resultado.roles[i].name;
            }
            for (
              var i = 0;
              i < response.data.success.resultado.listmenu.length;
              i++
            ) {
              for (
                var j = 0;
                j < response.data.success.resultado.permisos.length;
                j++
              ) {
                if (
                  response.data.success.resultado.listmenu[i].id ==
                  response.data.success.resultado.permisos[j].menu_id
                ) {
                  dataaux[con] = {
                    menu: response.data.success.resultado.listmenu[i].menu,
                    submenu:
                      response.data.success.resultado.permisos[j].accesos,
                    visualizar: false,
                    agregar: false,
                    editar: false,
                    eliminar: false,
                    visualizardisable: true,
                    agregardisable: true,
                    editardisable: true,
                    eliminardisable: true,
                  };
                  con++;
                }
              }
            }
            commit("LlenarItems", dataaux);
            commit("LlenarItemsSelect", select);
            commit("LlenarLoading", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    verRoles: async function ({ commit, state, dispatch }, datos) {
      const url = "permisos/rol";
      const data = { rol: datos };
      await axios
        .post(url, data)
        .then((response) => {
          state.Items.forEach((element) => {
            response.data.success.resultado.forEach((element2) => {
              if (
                element.menu == element2.menu &&
                element.submenu == element2.accesos
              ) {
                if (element2.permissions_id_visualizar != null) {
                  element.visualizardisable = false;
                  if (element2.visualizar == 1) {
                    element.visualizar = true;
                  } else {
                    element.visualizar = false;
                  }
                } else {
                  element.visualizardisable = true;
                }
                if (element2.permissions_id_agregar != null) {
                  element.agregardisable = false;
                  if (element2.agregar == 1) {
                    element.agregar = true;
                  } else {
                    element.agregar = false;
                  }
                } else {
                  element.agregardisable = true;
                }
                if (element2.permissions_id_editar != null) {
                  element.editardisable = false;
                  if (element2.editar == 1) {
                    element.editar = true;
                  } else {
                    element.editar = false;
                  }
                } else {
                  element.editardisable = true;
                }
                if (element2.permissions_id_eliminar != null) {
                  element.eliminardisable = false;
                  if (element2.eliminar == 1) {
                    element.eliminar = true;
                  } else {
                    element.eliminar = false;
                  }
                } else {
                  element.eliminardisable = true;
                }
              }
            });
          });
          /* console.log(state.Items)
        console.log(response.data) */
        })
        .catch((error) => {
          this.errorMessage = error.response.data;
          console.error("error!", error);
          dispatch("messageErrorAlert");
        });
    },
  },
};
