<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card>
            <v-card-text>
                <v-banner>
                    <v-autocomplete v-model="selected" @change="filtroRoles()" :items="ItemsSelect" class="mt-4" dense label="Seleccionar Roles"></v-autocomplete>

                    <template v-slot:actions>
                        <v-row class="light--text">
                            <v-col cols="4">
                                <v-checkbox input-value="true" disabled></v-checkbox>
                                Permiso asignado
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox disabled value></v-checkbox>
                                Permiso sin asignar
                            </v-col>
                            <v-col cols="4">

                                <v-checkbox disabled value indeterminate></v-checkbox>
                                No tiene esa Accion
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                            </v-col>
                            <v-col cols="4">
                            </v-col>
                            <v-col cols="4">
                            </v-col>
                        </v-row>
                    </template>
                </v-banner>
            </v-card-text>
        </v-card>
        <tabla Titulo="Lista Permisos" :Cabecera="Cabecera" :Items="Items" :Excel="false" :Crear="false" :Loading="Loading" />
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/permisos/tabla.vue"

import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
export default {
    name: "Permisos",
    components: {
        tabla
    },

    created() {
        const data = {
            menu: 1,
            submenu: 1
        };
        this.CargarMenu(data);
        this.cargarPermisos()
            .then(r => {
                console.log('como estas ?')
                this.filtroRoles()
            })
            .catch(error => {
                console.log('que tal ?')
            });
    },
    data() {
        return {
            selected: 'ADMINISTRADOR',
            Cabecera: [{
                    text: 'Menu',
                    value: 'menu'
                },
                {
                    text: 'Sub Menu',
                    value: 'submenu'
                },
                {
                    text: 'Visualizar',
                    value: 'visualizar'
                },
                {
                    text: 'Agregar',
                    value: 'agregar'
                },
                {
                    text: 'Editar',
                    value: 'editar'
                },
                {
                    text: 'Eliminar',
                    value: 'eliminar'
                },
            ]
        }
    },
    methods: {
        filtroRoles() {
            this.verRoles(this.selected)
        },
        ...mapActions('permisos', ['cargarPermisos', 'verRoles']),
        ...mapActions('nav', ['CargarMenu'])
    },

    computed: {
        ...mapGetters('permisos', ['Items', 'ItemsSelect']),
        ...mapState('permisos',['Loading'])
    }

};
</script>
