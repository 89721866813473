<template>
    <div>
        <Nuevo :dialog="dialog" :Item="itemSelected" v-if="dialog" @closeDialog="closeDialog" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />

        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} </span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://helpdesk.tiresoft.pe/#tipo_cambio"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>

            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                class="elevation-1" loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles"
                :loading="Loading"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.estado`]="{ item }">
                    <v-btn class="success" x-small v-if="item.estado == 1"> Activo </v-btn>
                    <v-btn class="error" x-small v-else> Inactivo </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions, mapState
} from "vuex";
import Nuevo from "./nuevo.vue";
import exportFromJSON from "export-from-json";
import MensajeTransicion from "../Alerta.vue"
import global from "@/global";

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear" ,"Loading"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: {},
            mensaje: "",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("roles", ["eliminarRoles"]),

        mensajeUsuarioNoAutorizado: function () {
            this.verAlerta = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        nuevoItem() {
            let autorizacion = global._autorizacion_usuario(this.Permisos, 28);
            if (autorizacion) {
                const fecha = new Date();
                this.itemSelected = {
                    id: 0,
                    valor: "",
                    fecha: this.formatoFecha(fecha, "yyyy-mm-dd")
                };
                this.dialog = true;
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },

        formatoFecha(fecha, formato) {
            //info del metodo => https://www.freecodecamp.org/espanol/news/javascript-date-now-como-obtener-la-fecha-actual-con-javascript/
            const map = {
                dd: fecha.getDate(),
                mm: (fecha.getMonth() + 1) / 10 < 1 ?
                    "0" + (fecha.getMonth() + 1) : fecha.getMonth() + 1, // esta logica es mia en caso de meses 7 lo convirte a 07
                //yy: fecha.getFullYear().toString().slice(-2),
                yyyy: fecha.getFullYear(),
            };
            return formato.replace(/dd|mm|yyyy/gi, (matched) => map[matched]);
        },
        editarItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = "download";
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            });
        },
    }
};
</script>
