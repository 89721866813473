<template>
    <div>
        <v-navigation-drawer :value="drawer" v-model="drawer" :mini-variant.sync="Mini" disable-resize-watcher app :width="drawerWidth">
            <v-img :class="{ 'img-content': true, 'small-content': Mini }"
                src="/images/background/user-img-background.jpg">
                <v-list-item :class="{ 'user-img px-2': true, 'small-image': Mini }">
                    <v-list-item-avatar @click="navigation()">
                        <v-img src="/images/background/user.png"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
                <div :class="{ 'user-details': true, 'small-details': Mini }">
                    <v-list-item-title style="font-size: 15px;">{{ name }}</v-list-item-title>
                    <v-list-item-title style="font-size: 14px;">{{ email }}</v-list-item-title>
                </div>
            </v-img>
            <v-divider></v-divider>

            <v-list dense v-for="(item_menu, index_menu) in items" :key="index_menu">
                <v-list-group :prepend-icon="item_menu.icon" @click="desplegarMenu(item_menu.id)"
                    :value="item_menu.desplegar" v-if="item_menu.menu_submenu.length > 0">
                    <template v-slot:activator>
                        <v-list-item-title>{{ item_menu.menu }}</v-list-item-title>
                    </template>

                    <v-list-item-group v-model="item_menu.selectedItem" :mandatory="false">
                        <div v-for="(item_submenu, index_submenu) in item_menu.menu_submenu" :key="index_submenu">
                            <v-list-item v-if="item_submenu.menu_submenu_pagina.length == 0"
                                @click="insertarRuta(item_menu.id, item_submenu.id, null, item_submenu.path, 'nivel2')">
                                <v-list-item-icon> </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap"
                                        v-text="item_submenu.description"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-group no-action sub-group v-else>
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-wrap">{{
                                            item_submenu.description
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>

                                <v-list-item-group v-model="item_submenu.selectedItemSub">
                                    <v-list-item v-for="(item, index) in item_submenu.menu_submenu_pagina" :key="index"
                                        @click="insertarRuta(item_menu.id, item_submenu.id, item.id, item.path, 'nivel3')">
                                        <v-list-item-icon> </v-list-item-icon>
                                        <v-list-item-title class="text-wrap" v-text="item.description"></v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list-group>
                        </div>
                    </v-list-item-group>
                </v-list-group>

                <v-list-item color="indigo" v-else @click="insertarRuta(item_menu.id, null, null, item_menu.path)">
                    <v-list-item-icon>
                        <v-icon>{{ item_menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap">{{ item_menu.menu }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <Bar :drawer="drawer" @drawer="drawer = $event" @revertirDespliegue="revertirDespliegue" />
    </div>
</template>
    
<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import Bar from "@/components/layout/Bar.vue";
export default {
    components: {
        Bar,
    },
    data() {
        return {
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            drawer: true,
            items: [],
            drawerWidth: 290,
        };
    },
    computed: {
        ...mapGetters("navigation", ["Mini", "Menu"]),
    },
    methods: {
        ...mapActions("navigation", ["Minimizar", "loadRoutes", "guardarAccesosMenu"]),

        insertarRuta: function (id, id_submenu, id_submenu_pagina, path, nivel) {
            this.navigation();

            if (this.$route.path != path) {
                if (path == "/salirCliente") {
                    localStorage.removeItem("idcliente");
                    this.$router.go({
                        path: path
                    });
                } else {
                    this.$router.push({
                        path: path
                    });
                }

                this.items.forEach((element) => {
                    //if SELECTEDITEM es null se revierte el despliegue de los items diferentes al id del menu seleccionado
                    if (element.id != id) {
                        element.selectedItem = null;
                    }
                    if (nivel == "nivel2") {
                        element.menu_submenu.forEach((element2) => {
                            element2.selectedItemSub = null;
                        })
                    }
                });
                let datosmenu = { id_menu: id, id_submenu: id_submenu, id_submenu_pagina: id_submenu_pagina };
                this.guardarAccesosMenu(datosmenu);
            }
            this.$forceUpdate();
        },
        desplegarMenu: function (id) {
            this.items.forEach(element => {
                if (element.id == id) {
                    element.desplegar = true;
                } else {
                    element.desplegar = false;
                }
            });
        },
        revertirDespliegue: function () {
            this.items.forEach(element => {
                element.desplegar = false;
                element.selectedItem = null;
            });
        },
        navigation() {
            if (this.Mini) {
                this.Minimizar(!this.Mini);
            }
        }
    },
    mounted: async function () {
        await this.loadRoutes(2);
        this.items = this.Menu;
        console.log("opcion 2");
    },
};
</script>
<style>
::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #c7c2c2;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #999595;
}
</style>