<template>
<div>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="650px" @click:outside="cerrarDialogo">
            <v-card>
                <v-toolbar height="50" color="#6B6A6A" dark>
                    <span class="text-h6">Comentarios de reporte</span>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-form ref="form" v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="10" md="10">
                                    <v-textarea class="letra-12" autocomplete="recomendacion" label="Recomendación" v-model="Item.comentario" :rules="[
                      (v) => !!v || 'La recomendación es requerida']" rows="2"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-btn :disabled="!valid" color="info" @click="enviar()" class="mt-5">
                                        <v-icon>mdi-send</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row class="mt-2">
                                <v-col cols="12" sm="12" md="12">
                                    <span class="black--text"><strong>Observaciones</strong></span>
                                    <hr>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-for="(item, index) in Comentarios" :key="index">
                                    <span class="black--text">
                                        <font color="#00B0E4">{{item.name}}</font>
                                    </span>
                                    <v-textarea class="letra-12" readonly autocomplete="recomendacion" v-model="item.comentario" rows="2" :hint="item.created_at" persistent-hint></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="cerrarDialogo()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import {axios2 as axios} from "@/interceptor/useApi";
export default {
    props: ['dialog', 'Item'],
    methods: {
        agregar() {
            //this.cerrarDialogo();
            //this.$alertify.success(this.Item.id == 0 ? "Agregado" : "Actualizado");
        },
        async enviar() {
            console.log("abc");
            try {
                const url = "comentarios/store";
                let datos = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    comentario: this.Item,
                };
                await axios
                    .post(url, datos)
                    .then((response) => {
                        this.cerrarDialogo();
                        this.$alertify.success("Agregado");
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        cerrarDialogo() {
            this.$emit('dialog', false);
            this.$refs.form.reset();
        }
    },
    data() {
        return {
            valid: false
        };
    },
    computed: {
        ...mapState("reporteconsolidado", ["Comentarios"])
    }
}
</script>

<style scoped>
.letra-12 {
    font-size: 12px;
}
</style>
