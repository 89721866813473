<template>
  <v-card>
    <v-toolbar color="secondary" dark>
      <span class="text-h6">Neumáticos</span>
    </v-toolbar>
    <v-card-title>
      <div class="col-lg-2 col-sm-2 col-md-2 col-xs-12">
        <v-autocomplete
          :items="opcionesPagina"
          v-model="porPagina"
          @change="cargarComponenteNeumaticos"
          dense
        ></v-autocomplete>
      </div>
      <div class="col-lg-1 col-sm-1 col-md-1 col-xs-12"></div>
      <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
          @change="onBusqueda"
        ></v-text-field>
      </div>
    </v-card-title>

    <v-row justify="center">
      <vue-loaders
        v-if="loader == true"
        name="ball-beat"
        color="red"
        scale="1"
      ></vue-loaders>
    </v-row>

    <v-row
      justify="center"
      v-for="(item, index) in datosPaginados"
      :key="index"
    >
      <div
        class="col-lg-2 col-sm-6 col-md-6 col-xs-12 tooltip text-center"
        v-for="(item2, index2) in item"
        :key="index2"
      >
        <button
          type="button"
          style="
            border-radius: 15px;
            width: 145px;
            background: none;
            box-shadow: none;
          "
          @click="seleccionarNeumatico(item2)"
        >
          <img :src="imagen" alt="neumatico" class="imagen-neumatico2" />
          <p style="color: grey; text-shadow: none">
            {{ item2.descripcion }}
          </p>
        </button>

        <Description
          v-if="VerTooltip == true"
          class="tooltiptext"
          :title="titulo(item2.estado)"
          :subtitle="subtitulo(item2.estado)"
          :item="item2"
        ></Description>
      </div>
    </v-row>

    <v-row>
      <div class="col-lg-7 col-sm-6 col-md-6 col-xs-12"></div>
      <div class="col-lg-5 col-sm-6 col-md-6 col-xs-12">
        <div v-if="Items.length != 0">
          <div class="text-xs-center">
            <v-pagination
              v-model="pagina_actual"
              :length="paginas"
              circle
              @input="mostrarPagina"
            ></v-pagination>
          </div>
        </div>
      </div>
    </v-row>
  </v-card>
</template>
<script>
import Description from "./instalacionneumaticos/description.vue";
// import {axios2 as axios} from "@/interceptor/useApi";
import axios from "axios";
export default {
  components: {
    Description,
  },
  props: ["Items", "VerTooltip"],
  data() {

    return {
      Neumaticos: [],
      opcionesPagina: [
        { value: 6, text: 6 },
        { value: 12, text: 12 },
        { value: 18, text: 18 },
        { value: "", text: "All" },
      ],
      paginacionInicial: [],
      porPagina: 6,
      neumaticoSeleccionado: null,
      imagen: axios.defaults.imageURL + "images/llanta.png",
      search: "",
      loader: false,
      paginas: 0,
      pagina_actual: 1,
      datosPaginados: [],
    };
  },
  methods: {
    onBusqueda: function () {
      if (this.search == "") {
        this.cargarComponenteNeumaticos();
      } else {
        let aux = [];
        this.paginacionInicial.forEach((element) => {
          element.forEach((element1) => {
            let descripcion = element1.descripcion;
            if (descripcion.includes(this.search.toUpperCase()) == true) {
              aux.push(element1);
            }
          });
        });
        this.paginacion(aux.length, aux);
      }
    },
    paginacion: function (total, array) {
      this.Neumaticos = [];
      this.datosPaginados = [];
      let items_por_td = this.porPagina != "" ? this.porPagina : total;
      this.pagina_actual = 1;
      this.paginas = Math.ceil(total / items_por_td);
      var index = 0;
      for (let i = 0; i < this.paginas; i++) {
        var temporal = [];
        for (let j = 0; j < items_por_td && index < total; j++, index++) {
          temporal.push(array[index]);
        }
        this.Neumaticos.push(temporal);
      }
      this.datosPaginados.push(this.Neumaticos[this.pagina_actual - 1]);
    },

    seleccionarNeumatico: function (item) {
      this.$emit("seleccionarNeumatico", item);
    },

    cargarComponenteNeumaticos: function () {
      this.paginacion(this.Items.length, this.Items);
    },

    mostrarPagina: function () {
      this.datosPaginados = [];
      this.datosPaginados.push(this.Neumaticos[this.pagina_actual - 1]);
      this.$forceUpdate();
    },

    titulo: function (estado) {
      if (estado == 1) {
        return "Listo para instalar";
      } else if (estado == 2) {
        return "En reparación";
      } else if (estado == 3) {
        return "Instalado";
      } else if (estado == 4) {
        return "En reencauche";
      } else if (estado == 5) {
        return "Listo para instalar";
      } else {
        return "";
      }
    },

    subtitulo: function (estado) {
      if (estado == 1) {
        return "";
      } else if (estado == 2) {
        return "Registre el costo de reparación";
      } else if (estado == 3) {
        return "";
      } else if (estado == 4) {
        return "Registre el costo de reencauche";
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.paginacion(this.Items.length, this.Items);
    this.paginacionInicial = this.Neumaticos;
  },
};
</script>
<style scoped>
.imagen-neumatico2 {
  width: 60px;
  opacity: 1;
}
.imagen-neumatico2:hover {
  transition: transform .2s;
  transform: scale(1.25);  
}


.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 1px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  top: -85px;
  left: 15%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>