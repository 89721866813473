<template>
    <div>
        <v-row justify="center">
            <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
            >
            <v-card>
                <v-card-title>
                <span v-if="Item.id == 0" class="text-h5">Nuevo Role</span>
                <span v-else class="text-h5">Editar Role</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id > 0" >ID: {{Item.id}}</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <!-- <FormularioDinamico :campos="campos"/> -->
                    <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                        <v-text-field
                        label="Nombre del rol *"
                        hide-details="auto"
                        v-model="Item.name"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                        <v-text-field
                        hide-details="auto"
                        label="Descripción *"
                        v-model="Item.description"
                        required
                        ></v-text-field>
                    </v-col>
                    </v-row>
                </v-container>
                <small>*indica los campos requeridos</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    @click="cerrarDialogo()"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    color="info"
                    :loading="loading"
                    @click="agregar()"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import {axios2 as axios} from "@/interceptor/useApi";

export default {
    props:['dialog','Item'],
    methods:{
        ...mapActions('roles',["cargarRoles"]),
        async agregar(){
            this.loading = true;
            const  url = "roles/store"
            const data = {role: this.Item};  
            await axios.post(url,data)
            .then(response => { 
                this.$alertify.success(this.Item.id  == 0 ? "Agregado" : "Actualizado"); 
                this.cerrarDialogo();    
            })
            .catch(error => {
                this.loading = false;
                this.errorMessage = error.response.data.error;
                this.$alertify.error(this.errorMessage); 
                console.error("error!", error);
            }); 
        },
        cerrarDialogo(){
            this.loading = false;
            this.cargarRoles();  
            this.$emit('dialog',false);
        }
    },
    data() {
        return {
            loading: false
        };
    },
}
</script>
