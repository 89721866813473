<template>
  <div class="pa-5">
    <v-col cols="12">
      <v-card class="pb-2">

        <v-toolbar color="secondary" dark>
          <span class="text-h6">Índice de Reencauchabilidad</span>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                <a target="_blank" href="https://helpdesk.tiresoft.pe/#indice_reencauchabilidad"
                  style="text-decoration:none !important; color: white;">
                  <v-icon dark>mdi-help-circle</v-icon>
                </a>
              </v-btn>
            </template>
            <span>¿Necesitas ayuda?</span>
          </v-tooltip>
        </v-toolbar>

        <!-- <v-card-title> Índice de Reencauchabilidad </v-card-title> -->
        <v-card-text>
          <v-form ref="form" enctype="multipart/form-data">
            <v-row>
              <v-col cols="12" sm="6" md="5">
                <v-text-field type="text" label="Cliente" v-model="Cliente.razon_social" hide-details="auto" readonly
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field type="text" label="RUC" v-model="Cliente.ruc" hide-details="auto" readonly
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-select :items="Years" v-model="item.anio" label="Año *" variant="underline" class="mt-5"
                  @change="filtrar" dense></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="loader == true">
      <v-row justify="center">
        <vue-loaders name="ball-beat" color="red" scale="1"></vue-loaders>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-card class="pb-3">
        <v-card-title> KPI </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <table class="table-responsive letra-9">
              <tr>
                <td rowspan="2" class="pa-3">
                  <font color="black"><strong><em>Índice de Reencauchabilidad : </em></strong></font>
                </td>
                <td class="text-center border-bottom pa-1">
                  <font color="black"><em>Número de veces reencauche</em></font>
                </td>
              </tr>
              <tr>
                <td class="text-center pa-1">
                  <font color="black"><em>Total de carcasas reencauchadas</em></font>
                </td>
              </tr>
            </table>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title> Año: {{ annio }}</v-card-title>
        <v-card-subtitle>Registro Anual de Indicadores</v-card-subtitle>
        <v-card-text>
          <div class="table-responsive">
            <table class="letra-9" style="width: 100%" id="table">
              <tr style="background-color: #6d6b6b">
                <td class="border texto-cen pa-2" style="width: 5%">
                  <strong>
                    <font color="white">Proceso</font>
                  </strong>
                </td>
                <td class="border texto-cen pa-2" style="width: 5%">
                  <strong>
                    <font color="white">Indicador</font>
                  </strong>
                </td>
                <td class="border texto-cen pa-2 pl-9 pr-9" style="width: 20%">
                  <strong>
                    <font color="white">Criterio de aceptación</font>
                  </strong>
                </td>
                <td class="border texto-cen pa-2" style="width: 6%" v-for="(item, index) in meses" :key="index">
                  <strong>
                    <font color="white">{{ item }}</font>
                  </strong>
                </td>
              </tr>
              <tr v-if="IndicesReencauchabilidadPorMes.length > 0">
                <td class="border texto-cen pa-1">KPI</td>
                <td class="border texto-cen pa-1">Índice de reencauchabilidad</td>
                <td class="border texto-cen letra-8">
                  <div style="background-color: #1ac10c">
                    <strong>
                      <font color="white">( Objetivo > {{ IReencauchabilidad.criterio_superior }} )</font>
                    </strong>
                  </div>
                  <div style="background-color: #fcc109">
                    ( Tolerable [ {{ IReencauchabilidad.tolerable_inferior }} - {{ IReencauchabilidad.tolerable_superior
                    }}] )
                  </div>
                  <div style="background-color: #f54c31">
                    <strong>
                      <font color="white">( Deficiente < {{ IReencauchabilidad.tolerable_inferior }} )</font>
                    </strong>
                  </div>
                </td>
                <td v-for="(item, index) in IndicesReencauchabilidadPorMes" :key="index" :class="pintarIndice(item)">
                  {{ valorTd(item) }}
                </td>
              </tr>
              <tr v-else>
                <td :colspan="15" class="border texto-cen pa-2">
                  No se registraron los Indicadores del Costo x Kilometro
                </td>
              </tr>
            </table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" class="rounded-pill" @click="exportarAexcel" small>Exportar Excel</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title>Gráfico estadístico de índice de reencauchabilidad</v-card-title>
        <v-card-text>
          <GraficoBar Title="Índice de reencauchabilidad" Labelsdataset="Índice de reencauchabilidad" Labelscale_x="Meses"
            Labelscale_y="Reencauchabilidad" IdGrafico="graficoreencauchabilidad" :Labels="Labels"
            :DatosGrafico="DatosGrafico" v-if="mostrarGrafico == true"></GraficoBar>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import GraficoBar from "../components/GraficoBar.vue";
import global from "../global"

export default {
  components: {
    GraficoBar
  },
  data() {
    return {
      item: {},
      annio: "",
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      loader: false,
      DatosGrafico: [],
      Labels: [],
      mostrarGrafico: false
    };
  },
  computed: {
    ...mapState("cliente", ["Cliente"]),
    ...mapState("reporteconsolidado", ["Years"]),
    ...mapState("indicadores", ["IReencauchabilidad", "IndicesReencauchabilidadPorMes"]),
  },
  methods: {
    ...mapActions("reporteconsolidado", ["cargarAnios"]),
    ...mapActions("indicadores", ["reporteIndiceReencauchabilidad"]),

    filtrar: async function () {
      this.loader = true;
      this.mostrarGrafico = false;
      this.annio = this.item.anio;
      await this.reporteIndiceReencauchabilidad(this.item.anio);
      this.Labels = this.meses;
      this.DatosGrafico = [];
      this.IndicesReencauchabilidadPorMes.forEach(element => {
        this.DatosGrafico.push(element);
      });
      this.mostrarGrafico = true;
      this.loader = false;
    },

    exportarAexcel: function () {
      location.href =
        global.ruta_api + "excel/reporteindicereencauchabilidad?" +
        global._json_to_query_string({
          id_cliente: localStorage.getItem("idcliente"),
          id_usuario: localStorage.getItem("id"),
          year: this.annio
        });
    },

    pintarIndice: function (item) {
      let pintar = "border texto-cen pa-1";
      if (item != "") {
        if (
          item >= this.IReencauchabilidad.tolerable_inferior &&
          item <= this.IReencauchabilidad.tolerable_superior
        )
          pintar = "border texto-cen pa-1 bg-celda-amarilla";
        if (item > this.IReencauchabilidad.criterio_superior)
          pintar = "border texto-cen pa-1 bg-celda-verde";
        if (item < this.IReencauchabilidad.criterio_inferior)
          pintar = "border texto-cen pa-1 bg-celda-roja";
      }
      return pintar;
    },

    valorTd: function (item) {
      if (item != "") {
        item = item.toFixed(2);
      }
      return item;
    }
  },
  created() {
    this.cargarAnios();
    var moment = require("moment");
    let fecha = moment(new Date(), "YYYY-MM-DD"); //2021-05-20 //YYYY-MM-DD
    this.annio = fecha.format("YYYY");
    this.item.anio = fecha.format("YYYY");
    this.filtrar();
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-bottom {
  border-bottom: solid;
  border-width: 1px;
}

.border-top {
  border-top: solid;
  border-width: 1px;
}

.border-left {
  border-left: solid;
  border-width: 1px;
}

.border-right {
  border-right: solid;
  border-width: 1px;
}

.no-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-border-left {
  border-left: none;
}

.no-border-right {
  border-right: none;
}

.texto-cen {
  text-align: center;
}

.texto-izq {
  text-align: left;
}

.texto-just {
  text-align: justify;
}

.inline {
  display: inline-block;
}

.letra-6 {
  font-size: 6pt;
}

.letra-7 {
  font-size: 7pt;
}

.letra-8 {
  font-size: 8pt;
}

.letra-9 {
  font-size: 9pt;
}

.letra-10 {
  font-size: 10pt;
}

.letra-11 {
  font-size: 11pt;
}

.letra-12 {
  font-size: 12pt;
}

.letra-14 {
  font-size: 14pt;
}

.letra-15 {
  font-size: 15pt;
}

.bg-celda-amarilla {
  background: #faec5c;
  /*color:#EDDF04;*/
}

.bg-celda-verde {
  background: #a8fa6f;
}

.bg-celda-roja {
  background: #f55f50;
}

#table {
  display: block;
  /* important */
  width: 1000px;
  overflow-x: scroll;
}
</style>
