<template>
    <div>
        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }}</span>
                <v-spacer></v-spacer>
                <!-- <v-btn class="mx-3" fab dark small color="#FFC107" >
                <a target="_blank" href="https://helpdesk.tiresoft.pe/#plantas" style="text-decoration:none !important; color: white;"><v-icon dark> mdi-text-box-search-outline </v-icon></a>
            </v-btn> -->
            </v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="Loading" loading-text="Cargando... Por favor, espere" class="mt-3 elevation-1"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                  'items-per-page-text': 'Resultados por página:',
                  'page-text': '{0}-{1} de {2}' 
                }">
                <template v-slot:[`item.iconos`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="item.estado == 'APROBADO'" height="22" width="22" color="blue lighten-2" fab
                                x-small dark @click="descargar()" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Descargar</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="item.estado == 'RECHAZADO'" height="22" width="22" color="blue lighten-2" fab
                                x-small dark @click="ver(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver pdf</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="item.estado == 'RECHAZADO'" height="22" width="22" color="#FF9600" fab x-small
                                dark @click="comentar(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-comment-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Comentar</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="success" fab x-small dark @click="aprobarItem(item)"
                                v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-playlist-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Aprobar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="red" fab x-small dark @click="rechazarItem(item)"
                                v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-playlist-remove</v-icon>
                            </v-btn>
                        </template>
                        <span>Rechazar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <Comentario :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected" />

        <v-dialog v-model="dialogPDF" persistent max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Reporte Consolidado (PDF) </span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <vue-loaders v-if="loader == true" name="ball-beat" color="red" scale="1"></vue-loaders>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <iframe @load="loader = false" id="ifrm" :src="endpoint + 'reporte/pdf/' + pdf.uuid"
                                    width="700" height="400"></iframe>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dialogPDF = false"> Cerrar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import axios from "axios";
import router from "@/router";
import { notif } from "@/plugins/notyf"
import Comentario from '../administradorreporte/comentarios.vue';

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "Item",
        "ItemTotal",
        "Excel",
        "Crear",
        "Loading"
    ],
    components: {
        Comentario
    },
    data() {
        return {
            itemSelected: {},
            search: "",
            mostrarIcono: true,
            dialogDelete: false,
            dialog: false,
            dialogPDF: false,
            endpoint: "",
            pdf: "",
            loader: false
        };
    },
    computed: {

    },
    methods: {
        ...mapActions("reporteconsolidado", ["cargarDatosAdministradorReportes", "cargarComentarios"]),

        nuevoItem: function () { },

        descargar: function () {
            router.push("/reporteconsolidado");
        },

        ver: async function (item) {
            const data = {
                id_cliente: localStorage.getItem("idcliente"),
                id_usuario: localStorage.getItem("id"),
                year: item.consulta_anio,
                month1: item.consulta_mes,
                month2: item.consulta_mes
            };

            try {
                const url = "reporte/savePdf";
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.pdf = response.data.datos;
                        this.endpoint = response.data.endpoint;
                        this.dialogPDF = true;
                        this.loader = true;
                    })
                    .catch((error) => {
                        console.error("error!", error);
                        this.errorMessage = error.response.data;
                    });
            } catch (error) {
                console.log(error);
            }
        },

        comentar: async function (item) {
            //cargar los comentarios
            await this.cargarComentarios();
            //enviar itemSelected como objeto
            this.itemSelected = {
                adm_rep_id: item.id,
                comentario: null
            }
            this.dialog = true;
        },

        aprobarItem: async function (item) {
            try {
                const url = "administradorreporte/approve";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_reporte: item.id
                };

                await axios
                    .post(url, data)
                    .then((response) => {
                        this.$emit('refrescar');
                        notif.success("Reporte aprobado");
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        rechazarItem: async function (item) {
            try {
                const url = "administradorreporte/decline";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_reporte: item.id
                };

                await axios
                    .post(url, data)
                    .then((response) => {
                        this.$emit('refrescar');
                        notif.success("Reporte rechazado");
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>
