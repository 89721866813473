<template>
  <div>
    <v-card>
      <v-toolbar color="secondary" dark>
        {{ Titulo }} : {{ ItemTotal }}

        <v-spacer></v-spacer>
        <v-btn class="mx-2" fab dark small color="orange">
          <v-icon dark> mdi-alert </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn
          v-if="Excel"
          color="primary"
          dark
          small
          class="mb-2"
          @click="DescargarExcel()"
        >
          Descargar Tabla
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          v-if="Crear"
          color="primary"
          dark
          class="mb-2"
          @click="nuevoItem()"
        >
          Nuevo
        </v-btn>
      </v-card-title>
        <v-data-table
          dense
          :headers="Cabecera"
          :items="Items"
          :items-per-page="10"
          :search="search"
          :loading="_loading" 
          class="elevation-1"
          loading-text="Cargando... Por favor, espere"
          no-data-text="No hay datos disponibles"
          :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  height="22"
                  width="22"
                  color="blue lighten-2"
                  fab
                  x-small
                  dark
                  @click="editarItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue lighten-2"
                  x-small
                  dark
                  @click="verImagen(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  Ver imagen
                </v-btn>
              </template>
              <span>Imagen</span>
            </v-tooltip>
          </template>
        </v-data-table>
    </v-card>

    <Imagen ref="Imagen" :dialog="dialog_image" @dialog="dialog_image = $event" :Item="itemSelected"/>

    <Editar ref="scrap" Titulo="Editar neumático en Scrap" :dialog="dialog_edit" @dialog="dialog_edit = $event" :Item="itemSelected" :MotivosScrap="MotivosScrap"/>
  </div>
</template>

<script>
import exportFromJSON from "export-from-json";
import Imagen from "../scrap/imagen.vue";
import Editar from "../scrap/editar.vue";
export default {
  props: ["Titulo", "Cabecera", "Items", "ItemTotal", "Excel", "Crear", "MotivosScrap", "_loading"],
  components: {
    Imagen,
    Editar
  },
  data() {
    this._loading= true;
    return {
      search: "",
      dialog_image: false,
      dialog_edit: false,
      _loading: false,
      itemSelected: {}
    };
  },
  methods: {
    editarItem(item) {
      item.fecha = item.fecha_scrap;
      this.itemSelected = item;
      this.dialog_edit = true;
    },

    verImagen(item){
      this.itemSelected = item;
      this.dialog_image = true;
    },

    DescargarExcel() {
      const data = this.Items;
      const nombreArchivo = "download";
      const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt
      exportFromJSON({ data, nombreArchivo, exportType });
    },
  },
};
</script>
<style scoped>
.letra-8 {
  font-size: 8pt;
}
.letra-10 {
  font-size: 10pt;
}
.letra-14 {
  font-size: 14pt;
}
.bg-red {
  background-color: #f44336 !important;
  color: #fff;
}
</style>