<template>
    <div>
        <v-row justify="center">
            <v-col lg="12">
                <v-card>

                    <v-toolbar color="secondary" dark>
                        <span class="text-h6"> {{ Titulo }}: {{ ItemTotal }}</span>
                        <v-spacer></v-spacer>
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                    <a target="_blank" href="https://helpdesk.tiresoft.pe/#reporte_inspeccion"
                                        style="text-decoration:none !important; color: white;">
                                        <v-icon dark>mdi-help-circle</v-icon>
                                    </a>
                                </v-btn>
                            </template>
                            <span>¿Necesitas ayuda?</span>
                        </v-tooltip>
                    </v-toolbar>

                    <!-- <v-toolbar color="secondary" dark>
                    {{ Titulo }} : {{ ItemTotal }}
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" fab dark small color="orange">
                        <v-icon dark> mdi-alert </v-icon>
                    </v-btn>
                </v-toolbar> -->
                    <v-card-title>
                        <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                            Descargar Tabla
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                            hide-details></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                            Nuevo
                        </v-btn>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" class="mr-3" dark v-bind="attrs" v-on="on">
                                    Exportar
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in opcionesAexportar" :key="index"
                                    @click="exportarAExcel1(item.text)">
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="success" dark v-bind="attrs" v-on="on">
                                    Exportar General
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in opcionesAexportar" :key="index"
                                    @click="exportarAExcel2(item.text)">
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>

                    <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                        :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1"
                        v-if="mostrarTabla" no-data-text="No hay datos disponibles" :footer-props="{
                            'items-per-page-text': 'Resultados por página:',
                            'page-text': '{0}-{1} de {2}'
                        }">
                        <template v-slot:[`item.acciones`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn style="margin-left: 2px" height="22" width="22" color="blue lighten-2" fab
                                        x-small dark @click="editarItem(item)" v-bind="attrs" v-on="on">
                                        <v-icon x-small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn style="margin-left: 2px; margin-top: 2px" height="22" width="22"
                                        color="#FE9B2F" fab x-small dark @click="detallesItem(item)"
                                        :loading="item.loader" v-bind="attrs" v-on="on">
                                        <v-icon x-small>mdi-format-list-bulleted</v-icon>
                                    </v-btn>
                                </template>
                                <span>Detalles</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center">
            <vue-loaders v-if="loadingExport == true" name="ball-pulse" color="#FF5252" scale="1"></vue-loaders>
        </v-row>

        <v-dialog v-model="dialognuevo" max-width="950px" @click:outside="cerrarModalNuevo">
            <Nuevo :Item="itemSelected" :Cliente="Cliente" @cargarPosiciones="cargarPosiciones"
                @editarInspeccion="editarInspeccionMinified" @fechavalida="fechavalida"
                @cerrarModalNuevo="cerrarModalNuevo">
            </Nuevo>
        </v-dialog>

        <v-dialog v-model="dialogdetalles" width="1100" @click:outside="cerrarModalDetallesPorIdentificador">
            <TablaDetalles :Inspecciones="Inspecciones" @editarInspeccionParcial="editarInspeccionParcial"
                :Identificador="identificador" :mostrarBotonEliminar="false" v-if="verTablaDetalles" />
        </v-dialog>

        <Detalle ref="detalle" :dialog="dialog" :Item="itemSelected" :Ejes="Ejes"
            :MotivosInaccesibilidad="MotivosInaccesibilidad" :TiposValvula="TiposValvula" :Duales="duales"
            :Observaciones="observaciones" @agregarInspeccionParcial="agregarInspeccionParcial"
            @cerrarModal="cerrarModal" v-if="dialog" />
    </div>
</template>

<script>
import Nuevo from "./nuevo.vue";
import axios from "axios";
import TablaDetalles from "@/components/inspeccion/detalles.vue";
import Detalle from "@/components/inspeccion/detalle.vue";
import global from "@/global";

import {
    mapState,
    mapActions
} from "vuex";

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "ItemTotal",
        "Excel",
        "Crear",
    ],
    components: {
        Nuevo,
        Detalle,
        TablaDetalles
    },
    data() {
        return {
            search: "",
            itemSelected: {},
            Inspecciones: [],
            loader: false,
            dialog: false,
            dialognuevo: false,
            dialogdetalles: false,
            duales: [{
                opcion: false,
                text: "Diseño",
            },
            {
                opcion: false,
                text: "Tamaño",
            },
            {
                opcion: false,
                text: "Tipo de Construcción",
            },
            {
                opcion: false,
                text: "Medida de Neumático",
            },
            {
                opcion: true,
                text: "No Aplica",
            },
            ],
            observaciones: [{
                name: "Desg. Irregular",
                opcion: false,
                text: "Desg. Irregular",
            },
            {
                name: "Lista para Reparar",
                opcion: false,
                text: "Para Reparar",
            },
            {
                name: "Aro Defectuoso",
                opcion: false,
                text: "Aro Defectuoso",
            },
            {
                name: "Fallas en el flanco",
                opcion: false,
                text: "Fallas en el flanco",
            },
            ],
            identificador: "",
            mostrarTabla: true,
            verTablaDetalles: false,
            opcionesAexportar: [
                {
                    id: 1,
                    text: "Exportar Excel",
                },
                {
                    id: 2,
                    text: "Exportar CSV",
                },
            ],
            loadingExport: false
        };
    },
    created() {
        this.ObtenerDatosCliente();
        this.cargarDatosAdicionales();
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
        ...mapState("inspeccion", [
            "Ejes",
            "MotivosInaccesibilidad",
            "TiposValvula",
        ]),
    },
    methods: {
        ...mapActions("cliente", ["ObtenerDatosCliente"]),
        ...mapActions("inspeccion", [
            "cargarInspecciones",
            "cargarInspeccionesPorIdentificador",
            "cargarDatosAdicionales",
        ]),

        cargarPosiciones: function () { },

        fechavalida: function (fecha) {
            this.itemSelected.fecha_inspeccion = fecha;
        },

        editarItem: function (item) {
            this.itemSelected = {
                ...item,
            };
            this.itemSelected.codigo =
                this.itemSelected.insp_codigo == "-" ?
                    null :
                    this.itemSelected.insp_codigo;
            this.itemSelected.repuesto =
                this.itemSelected.repuesto == null ? 0 : this.itemSelected.repuesto;
            this.itemSelected.portallantas =
                this.itemSelected.portallantas == null ?
                    0 :
                    this.itemSelected.portallantas;
            this.dialognuevo = true;
        },

        detallesItem: async function (item) {
            item.loader = true;
            this.verTablaDetalles = true
            this.identificador = item.identificador;
            let array_id_inspecciones = await this.cargarInspeccionesPorIdentificador(
                this.identificador
            );
            try {
                const url = "inspecciones/obtenerInspeccionesParcial";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    array_id_inspecciones: array_id_inspecciones,
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.Inspecciones = response.data.inspecciones;
                        item.loader = false;
                        this.dialogdetalles = false;
                        this.$nextTick().then(() => {
                            this.dialogdetalles = true;
                        });
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cerrarModalDetallesPorIdentificador: function () {
            this.mostrarTabla = false;
            this.verTablaDetalles = false;
            this.dialogdetalles = false;
            this.$nextTick().then(() => {
                this.mostrarTabla = true;
            });

        },

        cerrarModalNuevo: function () {
            this.mostrarTabla = false;
            this.dialognuevo = false;
            this.$nextTick().then(() => {
                this.mostrarTabla = true;
            });
        },

        editarInspeccionParcial: function (item) {
            this.itemSelected = {
                ...item,
            };
            item.array_resultado.forEach((element1) => {
                this.duales.forEach((element2) => {
                    if (element1 == element2.text) {
                        element2.opcion = true;
                    }
                });
            });

            item.array_otros.forEach((element1) => {
                this.observaciones.forEach((element2) => {
                    if (element1 == element2.name) {
                        element2.opcion = true;
                    }
                });
            });
            this.duales = this.duales;
            this.observaciones = this.observaciones;
            this.dialog = true;
        },

        agregarInspeccionParcial: async function (id) {
            //cargarInspeccion
            this.detallesItem({
                identificador: this.itemSelected.identificador
            });
            this.dialog = false;
        },

        editarInspeccionMinified: async function (item) {
            this.cerrarModalNuevo();
            await this.cargarInspecciones();
        },

        cerrarModal: function () {
            this.dialog = false;
        },

        async exportarAExcel1(extension1) {
            try {
                this.loadingExport = true;
                const url =
                    global.ruta_api +
                    "excel/reporteinspeccionesminified?" +
                    global._json_to_query_string({
                        id_cliente: localStorage.getItem("idcliente"),
                        extension: extension1,
                    });

                await axios({ url: url, method: "GET", responseType: "blob" })
                    .then(async (response) => {
                        this.loadingExport = false;
                        console.log("loading2", this.loadingExport);
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        async exportarAExcel2(extension2) {
            try {
                this.loadingExport = true;
                const url =
                    global.ruta_api +
                    "excel/reporteinspecciones?" +
                    global._json_to_query_string({
                        id_cliente: localStorage.getItem("idcliente"),
                        id_usuario: localStorage.getItem("id"),
                        extension: extension2,
                    });

                await axios({ url: url, method: "GET", responseType: "blob" })
                    .then(async (response) => {
                        this.loadingExport = false;
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>
