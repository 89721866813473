<template>
<div class="pa-4 mt-4">
    <v-row>
        <v-col cols="12" md="4" sm="12">
            <v-form enctype="multipart/form-data" ref="form" lazy-validation>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <div align="center">
                                    <img id='imagenFirmaPrevio' :src="Item.imagen? Item.imagen : imagen" alt="imagen_no_disponible" width='120' height='120'>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field label="Razon Social *" hide-details="auto" v-model="Item.razon_social" small :rules="requeridos"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field label="Ruc  *" hide-details="auto" v-model="Item.ruc" type="number" small :rules="requeridos"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field label="Dirección  *" hide-details="auto" v-model="Item.direccion" small :rules="requeridos"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <div class="col-md-12">
                                    <v-file-input id='img_firma' label="Subir imagen" filled prepend-icon="mdi-camera" accept="image/png, .jpeg, .jpg" @change="ObtenerImagen" :rules="Item.id > 0 ? [] : requeridos"></v-file-input>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="info" :loading="loadingperfil" @click="agregar()">
                            Actualizar
                        </v-btn>
                    </v-card-actions>
                    <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
                </v-card>
            </v-form>
        </v-col>

        <v-col cols="12" md="8" sm="12">
            <tabla Titulo="Miembros" :Cabecera="cabecera" :Items="items" :Roles="roles" :loading="loading" :Excel="false" :Crear="true" :Cliente="Item.id" />
        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
import global from '@/global';
import tabla from "@/components/miembros/tabla.vue";
import MensajeTransicion from "@/components/Alerta.vue"
import router from '@/router';

export default {
    name: "Micuenta",
    components: {
        tabla,
        MensajeTransicion
    },
    data() {
        return {
            Item: {},
            requeridos: [
                v => !!v || 'Este Campo es Requerido.'
            ],
            imagen: '/images/reporte/imagen_no_disponible.jpg',
            cabecera: [{
                    text: "Nombre",
                    value: "name",
                    align: "left",
                },
                {
                    text: "Email",
                    value: "email",
                    align: "left",
                },
                {
                    text: "Role",
                    value: "role",
                    align: "left"
                },
                {
                    text: "Acciones",
                    value: "accion",
                    align: "center"
                }
            ],
            items: [],
            search: "",
            loadingperfil: false,
            loading: false,
            verAlerta: false,
            Mensaje: "",
            roles: []
        }
    },
    computed: {
        ...mapState("cliente", ["Cliente", "Usuarios", "Roles"]),
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("cliente", ["ObtenerDatosCliente", "obtenerUsuariosPorCliente", "agregarCliente"]),

        ObtenerImagen() {
            let file = document.getElementById('img_firma').files[0];
            this.Item.ruta_logo = file;
            this.CargarImagen(file)
        },

        CargarImagen(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagenminiatura = e.target.result;
            }
            reader.readAsDataURL(file)
        },

        mensajeUsuarioNoAutorizado: function () {
            this.verAlerta = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        async agregar() {
            if (this.$refs.form.validate()) {
                this.loadingperfil = true;
                this.$alertify.confirm(
                    "Desea Editar Item",
                    () => {
                        let autorizacion = global._autorizacion_usuario(this.Permisos, 21);
                        if (autorizacion) {
                            this.agregarCliente(this.Item)
                                .then(r => {
                                    this.$alertify.success("Operación realizada con éxito")
                                    this.loadingperfil = false;
                                    setTimeout(() => {router.go()}, 1000);
                                });
                        } else {
                            this.mensajeUsuarioNoAutorizado();
                        }
                    },
                    () => {
                        this.cargarCliente()
                        this.$alertify.error("Se cancelo la acción")
                    }
                ).setHeader('<em> Aviso </em> ');
            }
        },
    },
    async mounted() {
        await this.obtenerUsuariosPorCliente();
        this.Item = this.Cliente;
        this.Item.imagen = global.ruta_image + this.Item.ruta_logo;
        this.items = this.Usuarios;
        this.roles = this.Roles;
        this.$refs.form.resetValidation();
    }
}
</script>
