<template>
    <div>
        <v-app-bar dense dark app color="grey darken-3" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-app-bar-nav-icon @click="navigation()"></v-app-bar-nav-icon>
            {{ Cliente.razon_social }}
            <v-spacer></v-spacer>

            <v-btn v-if="mostraricononotificaciones == true" class="mt-2" @click="openModalIncidencias()" icon>
                <v-icon>mdi-headset</v-icon>
            </v-btn>

            <v-menu offset-y v-if="mostraricononotificaciones == false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-badge v-if="cantidadincidentes > 0" color="green" :content="cantidadincidentes" class="mt-2">
                            <v-icon>mdi-headset</v-icon>
                        </v-badge>
                        <v-icon v-else class="mt-2">mdi-headset</v-icon>
                    </v-btn>
                </template>

                <v-list width="350" class="overflow-y-auto" max-height="400" three-line>
                    <template v-for="(item, index) in notificaciones">
                        <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

                        <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                        <v-list-item v-else :key="item.title">
                            <v-list-item-avatar>
                                <v-img :src="item.avatar"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-html="item.title"></v-list-item-title>
                                <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>

            <v-menu offset-y style="cursor: pointer;">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list :lines="false" dense>
                    <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                        <v-list-item-icon style="margin-right: 10px;">
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content @click="Logout()" style="margin-right: 10px;">
                            <v-list-item-title v-text="item.text" @click="Logout()"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list :lines="false" dense v-if="Download">
                    <v-list-item style="cursor: pointer;">
                        <v-list-item-icon style="margin-right: 10px;">
                            <v-icon v-text="Download.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content @click="Dowloadapp(Download.nombreapk)" style="margin-right: 10px;">
                            <!-- Texto del item -->
                            <v-list-item-title v-text="Download.text" @click="Dowloadapp(Download.nombreapk)"></v-list-item-title>
                            <!-- Versión del item en color verde -->
                            <v-list-item-subtitle>
                                <span style="color: green;">Version : {{ Download.version }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <FormularioIncidencias :dialog="dialogformularioincidencia" v-if="verformularioincidencia"
            @cerrarModalIncidencias="cerrarModalIncidencias" />
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FormularioIncidencias from "@/components/incidencias/nuevo.vue"

export default {
    props: ['drawer'],
    components: {
        FormularioIncidencias
    },
    data: () => ({
        selectedItem: 1,
        items: [
            { text: 'Cerrar la sesión', icon: 'mdi-account', funcion: 'Logout' },
        ],
        dowloads: [
            { text: 'Descargar App', icon: 'mdi-download', funcion: 'Dowloadapp', version: 'tiresoft-2.5', nombreapk: 'tiresoft-production.apk' },
        ],
        notificaciones: [],
        cantidadincidentes: 0,
        dialogformularioincidencia: false,
        verformularioincidencia: false,
        mostraricononotificaciones: false,
        mostrarcomponentenotificaciones: false,
        mostrarnotificaciones: false
    }),
    computed: {
        ...mapState('incidente', ["Incidentes"])
    },
    methods: {
        ...mapActions('navigation', ['Minimizar']),
        ...mapActions("versionapk", ["ObtenerVersionado"]),
        ...mapActions("cliente", ["ObtenerDatosCliente"]),
        ...mapActions("incidente", ["cargarIncidentes"]),

        obtenerincidentes() {
            console.log(this.Incidentes,'Incidentes');
            return this.Incidentes;
        },
        navigation() {
            console.log("🚀 ~ file: Bar.vue:75 ~ navigation ~ this.drawer:", this.drawer);
            console.log("🚀 ~ file: Bar.vue:77 ~ navigation ~ this.Mini:", this.Mini)
            if (!this.Mini && !this.drawer) {
                this.$emit('drawer', !this.drawer);
            } else {
                this.Minimizar(!this.Mini);
                this.$emit("revertirDespliegue");
            }
        },
        openModalIncidencias() {
            this.verformularioincidencia = true;
            this.dialogformularioincidencia = true;
        },
        cerrarModalIncidencias() {
            this.verformularioincidencia = false;
            this.dialogformularioincidencia = false;
        },
        Logout() {
            //localStorage.setItem('logueo','')
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
            //router.push('/login')
        },
        Dowloadapp(nombreapk) {
            // const url = "/downloads/tiresoft-developers.apk";     
            const url = "/downloads/"+nombreapk;   
            
            window.location.href = url;
            console.log('url > ',url);
            
            // console.log('Dowloadapp > ', localStorage);
        }
    },
    computed: {
        ...mapState("cliente", ["Cliente"]),
        ...mapState("versionapk", ["Download"]),
        ...mapGetters('navigation', ['Mini'])
    },
    async mounted() {
        let id_cliente = localStorage.getItem("idcliente");
        if (id_cliente == undefined || id_cliente == null) {
            this.mostraricononotificaciones = false;
            this.notificaciones = await this.cargarIncidentes();
            this.cantidadincidentes = this.notificaciones.length - 1;
        } else {
            this.mostraricononotificaciones = true;
        }
        this.ObtenerDatosCliente();
        this.ObtenerVersionado();

    }
}
</script>
