<template>
    <v-form enctype="multipart/form-data" ref="tabla-detalles">
        <v-card>
            <v-card-title>Inspecciones</v-card-title>

            <v-card-text>
                <v-row v-if="mostrarBotonEliminar == false">
                    <v-col lg="9">
                    </v-col>
                    <v-col lg="3">
                        <v-overflow-btn class="rounded-pill" :items="opcionesAexportar" label="Exportar"
                            target="#dropdown-example" v-model="extension2" @change="exportarAExcel2"
                            background-color="#CEECF5" dense :loading="loader" small></v-overflow-btn>
                    </v-col>
                </v-row>
                <div class="table-responsive pa-2 ">
                    <table class="table table-hover table-sm letra-10" style="width: 100%" :id="clase_table">
                        <!-- <thead class="sticky-top"> -->
                        <thead>
                            <tr style="background-color: #6d6b6b">
                                <th class="border texto-cen pa-1 pl-2 pr-2" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Opciones</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Pos</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Serie del neumático</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Marca</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Modelo</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Medida</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Diseño</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Eje</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Condición</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Número Reencauche </font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 60%" rowspan="2">
                                    <strong>
                                        <font color="white">Empresa reencauchadora </font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 10%" rowspan="2">
                                    <strong>
                                        <font color="white">Fecha Reencauche </font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 10%" rowspan="2">
                                    <strong>
                                        <font color="white">Presión</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 10%" rowspan="2">
                                    <strong>
                                        <font color="white">Tipo Presión</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 80%" colspan="3">
                                    <strong>
                                        <font color="white">Tapa de Pitón</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 50%" colspan="3">
                                    <strong>
                                        <font color="white">Profundidad de Rodado (mm)</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Duales mal hermanados</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Separación entre duales</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 50%" colspan="4">
                                    <strong>
                                        <font color="white">Observaciones</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 50%" colspan="2">
                                    <strong>
                                        <font color="white">Tuercas</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Estado</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Tipo costo</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Km de instalación</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Km de inspección</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Km recorrido</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Km proyectado</font>
                                    </strong>
                                </th>
                                <th class="border texto-cen pa-1 pl-4 pr-4" style="width: 20%" rowspan="2">
                                    <strong>
                                        <font color="white">Recomendación</font>
                                    </strong>
                                </th>
                            </tr>
                            <tr style="background-color: #6d6b6b">
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 40%">
                                    <strong>
                                        <font color="white">Tapa Pitón</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 20%">
                                    <strong>
                                        <font color="white">Accesibilidad</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 20%">
                                    <strong>
                                        <font color="white">Motivo de inaccesibilidad</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 10%">
                                    <strong>
                                        <font color="white">Izquierdo</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 10%">
                                    <strong>
                                        <font color="white">Medio</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 10%">
                                    <strong>
                                        <font color="white">Derecho</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 20%">
                                    <strong>
                                        <font color="white">Desg. Irregular</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="min-width: 20%">
                                    <strong>
                                        <font color="white">Para Reparar</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 20%">
                                    <strong>
                                        <font color="white">Aro Defectuoso</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 10%">
                                    <strong>
                                        <font color="white">Fallas Flanco</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 20%">
                                    <strong>
                                        <font color="white">Estado</font>
                                    </strong>
                                </td>
                                <td class="border texto-cen pa-1 pl-4 pr-4 thead2" style="width: 10%">
                                    <strong>
                                        <font color="white">Cantidad</font>
                                    </strong>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in Inspecciones" :key="index">
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                        @click="editarItem(item)">
                                        <v-icon x-small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn v-if="mostrarBotonEliminar == true" height="22" width="22" color="red" fab
                                        x-small dark @click="eliminarItem(item)">
                                        <v-icon x-small>mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">{{ item.d_posicion }}</td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">{{ item.num_serie }}</td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.marca }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.modelo }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.medida }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.disenio }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.eje }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.condicion }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.cantidad_reencauche }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.empresa_reencauche }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.fecha_reencauche }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.presion }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.tipo_presion }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_valvula }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_accesibilidad }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_motivo_inaccesibilidad }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.exterior }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.medio }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.interior }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.resultado }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.sep_duales }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_desgirregular }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_parareparar }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_arodefectuoso }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_fallasflanco }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.d_tuercaestado }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.tuercacantidad }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.estado }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.tipo_costo }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.km_instalacion }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.km_inspeccion }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.km_recorrido }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.km_proyectado }}
                                </td>
                                <td class="border texto-cen pa-1 pl-1 pr-1">
                                    {{ item.recomendacion }}
                                </td>
                            </tr>
                            <tr v-if="Inspecciones.length == 0">
                                <td class="border texto-cen" colspan="35">Not found Data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h3 class="text-center mt-2">
                                    Desea eliminar esta inspeccion ?
                                </h3>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dialog = false"> Cerrar </v-btn>
                    <v-btn color="info" @click="confirmar()"> Eliminar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>
    
<script>
import { mapState } from "vuex";
import global from "../../global";
import axios from "axios";
export default {
    props: ["Inspecciones", "mostrarBotonEliminar", "Identificador", "clase", "PermisoEditar"],
    data() {
        return {
            dialog: false,
            itemSelected: {},
            opcionesAexportar: [{
                id: 1,
                text: "Exportar Excel",
            },
            {
                id: 2,
                text: "Exportar CSV",
            },
            ],
            loader: false,
            extension2: "",
            clase_table: "table1"
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        editarItem: function (item) {
            //emite al componente tabla de la inspeccion
            this.$emit("editarInspeccionParcial", item);
        },
        eliminarItem: function (item) {
            this.dialog = true;
            this.itemSelected = item;
        },
        confirmar: function () {
            this.dialog = false;
            this.$emit("eliminarInspeccionParcial", this.itemSelected.id);
        },
        async exportarAExcel2() {
            try {
                this.loader = true;
                const url =
                    global.ruta_api +
                    "excel/reporteinspecciones?" +
                    global._json_to_query_string({
                        id_cliente: localStorage.getItem("idcliente"),
                        id_usuario: localStorage.getItem("id"),
                        extension: this.extension2,
                        identificador: this.Identificador
                    });

                await axios({
                    url: url,
                    method: "GET",
                    responseType: "blob"
                })
                    .then(async (response) => {
                        this.loader = false;
                        var nombre_archivo = response.headers["nombre-archivo"];
                        if (response.data.type == "text/html") {
                            // no es excel, probablemente es json con un mensaje de error
                            const res = JSON.parse(await response.data.text());
                            if (!res.exito) {
                                alert(res.mensaje || "Sucedió un error, intentelo nuevamente");
                            }
                        } else {

                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", nombre_archivo);
                            document.body.appendChild(link);
                            link.click();
                            this.extension2 = "";
                        }
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        /*
        cerrarDialogo: function () {
          this.$emit("cerrarModalDetallesPorIdentificador");
        },
        */
    },
    mounted() {
        if (this.clase != undefined) {
            this.clase_table = this.clase;
        }
    }
};
</script>
    
<style scoped>
table {
    border-collapse: collapse;
}

/*
    thead tr th {
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: #535252;
    }
    
    .thead2 {
      position: sticky;
      top: 61px;
      z-index: 10;
      background-color: #535252;
    }
    */

td {
    vertical-align: top;
}

.border {
    border-style: solid;
    border-width: 1px;
}

.border-bottom {
    border-bottom: solid;
    border-width: 1px;
}

.border-top {
    border-top: solid;
    border-width: 1px;
}

.border-left {
    border-left: solid;
    border-width: 1px;
}

.border-right {
    border-right: solid;
    border-width: 1px;
}

.no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.no-border-left {
    border-left: none;
}

.no-border-right {
    border-right: none;
}

.texto-cen {
    text-align: center;
}

.texto-izq {
    text-align: left;
}

.texto-just {
    text-align: justify;
}

.inline {
    display: inline-block;
}

.texto-arriba {
    vertical-align: text-top;
    vertical-align: top;
}

.letra-6 {
    font-size: 6pt;
}

.letra-7 {
    font-size: 7pt;
}

.letra-8 {
    font-size: 8pt;
}

.letra-9 {
    font-size: 9pt;
}

.letra-10 {
    font-size: 10pt;
}

.letra-11 {
    font-size: 11pt;
}

.letra-12 {
    font-size: 12pt;
}

.letra-14 {
    font-size: 14pt;
}

.letra-15 {
    font-size: 15pt;
}

.letra-18 {
    font-size: 18pt;
}

.letra-25 {
    font-size: 25pt;
}

.letra-30 {
    font-size: 30pt;
}

.margin-top-20 {
    margin-top: 20px;
}

#table1 {
    display: block;
    /* important */
    width: 1000px;
    overflow-y: scroll;
}

#table2 {
    display: block;
    /* important */
    width: 1000px;
    overflow-y: scroll;
    height: 400px;
    overflow-x: scroll;
}
</style>

<style>
.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
}

.table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
    vertical-align: middle;
    color: grey;
}

.table>thead {
    vertical-align: middle;
}

.table> :not(:first-child) {
    border-top: 2px solid currentColor;
}

.caption-top {
    caption-side: top;
}

.table-sm> :not(caption)>*>* {
    padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
    border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
    border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
    border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
    border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
}

.table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6;
}

.table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce;
}

.table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7;
}

.table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3;
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9;
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4;
}

.table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1;
}

.table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}


.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}

.table-container {
    overflow-x: auto;
    max-height: 400px;
    /* Establece la altura máxima de la tabla */
    position: relative;
}

.table {
    width: 100%;
    border-collapse: collapse;
}</style>
    