<template>
    <div>
        <Nuevo :dialog="dialog" @dialog="dialog = $event" :Item="itemSelected" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        <v-card>

            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} </span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://helpdesk.tiresoft.pe/#plantas"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                :loading="loading" loading-text="Cargando... Por favor, espere" class="elevation-1 mt-5"
                no-data-text="No hay datos disponibles"
                :footer-props="{
                    'items-per-page-text': 'Resultados por página:',
                    'page-text': '{0}-{1} de {2}'
                }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="red" fab x-small dark @click="eliminarItem(item)"
                                v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogDelete" persistent max-width="600px">
            <v-card>
                <v-card-text>
                    <v-container>
                        <div class="text-center letra-14 pa-2">
                            <strong>Desea eliminar la planta {{ itemSelected.nombre }} ?</strong>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="dialogDelete = false"> Cerrar </v-btn>
                    <v-btn color="info" @click="confirmar()">
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import axios from "axios";
import Nuevo from './nuevo.vue'
import MensajeTransicion from "../Alerta.vue"
import global from "@/global";
import {
    notif
} from "@/plugins/notyf"

export default {
    props: [
        "Titulo",
        "Cabecera",
        "loading",
        "Items",
        "Item",
        "ItemTotal",
        "Excel",
        "Crear"
    ],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            itemSelected: {},
            search: "",
            mostrarIcono: true,
            dialogDelete: false,
            dialog: false,
            mensaje: "",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("plantas", ["eliminarPlanta", "cargarPlantas"]),

        mensajeUsuarioNoAutorizado: function () {
            this.verAlerta = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        nuevoItem: function () {
            let autorizacion = global._autorizacion_usuario(this.Permisos, 51);
            if (autorizacion) {
                this.itemSelected = {
                    id: null,
                    nombre: null,
                    direccion: null
                }
                this.dialog = true;
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },
        editarItem: function (item) {
            this.itemSelected = {
                id: item.id,
                nombre: item.nombre,
                direccion: item.direccion
            }
            this.dialog = true;
        },

        eliminarItem: function (item) {
            let autorizacion = global._autorizacion_usuario(this.Permisos, 53);
            if (autorizacion) {
                this.itemSelected = {
                    id: item.id,
                    nombre: item.nombre,
                    direccion: item.direccion
                }
                this.dialogDelete = true;
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },
        async confirmar() {
            try {
                const url = "plantas/delete";
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_planta: this.itemSelected.id
                };

                await axios
                    .post(url, data)
                    .then((response) => {
                        this.cargarPlantas();
                        notif.success("Operación realizada con éxito");
                        this.dialogDelete = false;
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>
