<template>
    <div>
        <Nuevo :dialog="dialog" :Item="itemSelected" :Titulo="Titulo" @closeDialog="closeDialog" v-if="dialog" />
        <Contacto :dialog2="dialog2" @dialog2="dialog2 = $event" :ItemsCliente="itemCliente" :Titulo="Titulo" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        <v-card>
            <v-card-title>
                {{ Titulo }}
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="Crear" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dense :headers="Cabecera" :items="Items" :items-per-page="10" :search="search"
                    class="elevation-1" loading-text="Cargando... Por favor, espere"
                    no-data-text="No hay datos disponibles"
                    :loading="Loading"
                    :footer-props="{
                      'items-per-page-text': 'Resultados por página:',
                      'page-text': '{0}-{1} de {2}' 
                    }">
                    <template v-slot:[`item.ruta_logo`]="{ item }">
                        <v-chip v-if="item.ruta_logo" color="success" outlined small>
                            SI
                        </v-chip>
                        <v-chip v-else color="error" outlined small>
                            NO
                        </v-chip>
                    </template>

                    <template v-slot:[`item.img_firma`]="{ item }">
                        <v-chip v-if="item.img_firma" color="success" outlined small>
                            SI
                        </v-chip>
                        <v-chip v-else color="error" outlined small>
                            NO
                        </v-chip>
                    </template>

                    <template v-slot:[`item.rol`]="{ item }">
                        <v-chip color="success" outlined small>
                            {{ item.rol }}
                        </v-chip>
                    </template>

                    <template v-slot:[`item.accion`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                    @click="editarItem(item)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>

                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ml-1" height="22" width="22" color="deep-purple lighten-2" fab x-small
                                    dark @click="filtroContacto(item)" v-bind="attrs" v-on="on">
                                    <v-icon x-small>mdi-account</v-icon>
                                </v-btn>
                            </template>
                            <span>Ver Contacto</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions, mapState
} from 'vuex';
import Nuevo from './nuevo.vue'
import Contacto from './contacto.vue'
import exportFromJSON from "export-from-json"
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading"],
    components: {
        Nuevo,
        Contacto,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            dialog2: false,
            itemSelected: {},
            itemCliente: {
                id: 0,
                razon_social: ''
            },
            mensaje: "",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("cliente", ["verContacto"]),

        mensajeUsuarioNoAutorizado: function () {
            this.verAlerta = true;
            this.mensaje = "No esta autorizado para realizar la operación";
            setTimeout(() => [(this.verAlerta = false)], 4000);
        },

        filtroContacto(item) {
            this.itemCliente = {
                id: item.id,
                razon_social: item.razon_social
            };
            this.verContacto(item);
            this.dialog2 = true;
        },
        nuevoItem() {
            let autorizacion = global._autorizacion_usuario(this.Permisos, 20);
            if (autorizacion) {
                this.itemSelected = {
                    id: 0,
                    razon_social: '',
                    ruc: '',
                    estado: '',
                    ruta_logo: '',
                    costo: '',
                    imagen: '',
                    direccion: '',
                    id_sectors: ''
                }
                this.dialog = true;
            } else {
                this.mensajeUsuarioNoAutorizado();
            }
        },
        editarItem(item) {
            this.itemSelected = { ...item };
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            })
        }
    }
};
</script>
