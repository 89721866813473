import { render, staticRenderFns } from "./IndicadorReencauchabilidad.vue?vue&type=template&id=96e82c12&scoped=true&"
import script from "./IndicadorReencauchabilidad.vue?vue&type=script&lang=js&"
export * from "./IndicadorReencauchabilidad.vue?vue&type=script&lang=js&"
import style0 from "./IndicadorReencauchabilidad.vue?vue&type=style&index=0&id=96e82c12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96e82c12",
  null
  
)

export default component.exports